/* istanbul ignore file */
/* eslint-disable react/wrap-multilines */
import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';

/** **************************************
 * Function Name: getUserCohortAnnotationCount
 * Description: get user cohort detail.
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export const getUserCohortAnnotationCount = (cohortId) => {
	return new Promise((resolve, reject) => {
		apiGet('GetUserCohortAnnotationCount', {
			m_cohortId: cohortId,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_VALIDITY,
					payload: res.data.data,
				});

				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

export const searchConceptsById = (conceptIds, eventIds) => {
	return new Promise((resolve, reject) => {
		let params = {};
		params['conceptTableEntry.umls_concept_category'] = 'All';
		if (conceptIds) {
			params['conceptIds'] = conceptIds;
		}
		if (eventIds) {
			params['eventIds'] = eventIds;
		}
		apiGet('SearchConceptsById', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_CONCEPTS,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
/****************************************
 * Function Name: getEvent
 * Description: exports an action to get the Events
 * from the backend.
 * The data received is set in the reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export const getEvent = (eventIds) => {
	return new Promise((resolve, reject) => {
		apiGet('GetEvent', {
			eventType: 'outcome',
			eventIds: eventIds
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_EVENT,
					payload: res.data.data.reverse(),
				});
				resolve(res.data.data.reverse());
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: updateStudyValidation
 * Description: get detail of document based on document_concept_id or annotation id.
 * Method has one signature.
 ****************************************/
export const updateStudyValidation = (updatedStudyValidationValues) => {
	store.dispatch({
		type: actionTypes.UPDATE_STUDY_VALIDITY,
		payload: updatedStudyValidationValues,
	});
};
/****************************
 * Function Name: setSideNavList
 * Description: exports an action to set the side nav list
 * with the listitem components to be display the history to the user.
 * The data received gets set in the sidenav reducer which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - list - An Array having a list of React Components which need to
 * be rendered to the SideNav.
 *****************************/

export const setSideNavList = (list) => {
	store.dispatch({
		type: actionTypes.SET_SIDE_NAVLIST,
		payload: list,
	});
};
/** **************************************
 * Function Name: getStudyDetail
 * Description: exports an action to get the study details from the backend
 * The details received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export const getStudyDetail = (study_id) => {
	return new Promise((resolve, reject) => {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/** **************************************
 * Function Name: GetCohortDetailsForStudyValidity
 * Description: get kappa score for cohort.
 * Method has one signature.
 ****************************************/

export const GetCohortDetailsForStudyValidity = (cohortId) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortDetailsForStudyValidity', {
			m_CohortId: cohortId,
		})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: getDocumentConceptAnnotationResults
 * Description: get user document based on cohort and concept.
 * Method has one signature.
 ****************************************/
export const getDocumentConceptAnnotationResults = (
	cohortId,
	conceptId,
	m_studyArmType,
	eventId,
	Is_concept_value_result_type,
	m_conceptSeverity
) => {
	return new Promise((resolve, reject) => {
		apiGet('GetDocumentConceptAnnotationResults', {
			m_cohortIdLong: cohortId,
			m_conceptId: conceptId,
			m_studyArmType: m_studyArmType,
			m_eventId: eventId,
			Is_concept_value_result_type: Is_concept_value_result_type,
			m_conceptSeverity: m_conceptSeverity,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_VALIDITY,
					payload: res.data.payload,
				});
				resolve(res.data.payload);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: GetCohortKappaScores
 * Description: get kappa score for cohort.
 * Method has one signature.
 ****************************************/

export const GetCohortKappaScores = (cohortId) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortKappaScores', {
			m_CohortId: cohortId,
		})
			.then((res) => {
				resolve(res.data.payload);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const setHistoory = (list) => {
	store.dispatch({
		type: actionTypes.SET_HISTORY,
		payload: list,
	});
};
/****************************************
 * Function Name: setDocName
 * Description: exports an action to set the document name
 * in the reducer.
 * Method has one signature.
 * @param - doc_name - Document Name that has to be set in the reducer.
 ****************************************/

export const setDocName = (doc_name) => {
	store.dispatch({
		type: actionTypes.SET_DOC_NAME,
		payload: doc_name,
	});
};

export const getStudyValidationCriteria = (study_id) => {
	const value = sessionStorage.getItem(study_id + 'studyCriteria');
	return value ? JSON.parse(value) : '';
};
