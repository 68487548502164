import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import actions from '../../../actions';
import MessagePopup from '../../../components/MessagePopup/MessagePopup';
import { withRouter } from 'react-router-dom';
import Copyright from '../../../components/Copyright/Copyright';
import SaveConfirm from '../../../components/SaveConfirm/SaveConfirm';
import { roleAccess } from '../../../utilities/AppConstants';
import { Link } from 'react-router-dom';
import { setIsLoading } from '../../../actions/common';
import {
	addDerivedAnnotation,
	AddPatientManualAnnotation,
	DeletePatientManualAnnotations,
	getDerivedManualAnnotationsData,
	getEncounterLevelManualAnnotation,
	getPatientList,
	getPatientManualAnnotationsData,
	getSubdocumentById,
	getUserDetails,
	openModal,
	getDescriptorValues,
	getSeverityValues,
	getProgressionValues,
	getManualAnnotationProjectAttributes,
	getStudyOutcomes,
} from './DataPatientLevelAnnotationHelper';

const EncounterLevelAnnotationPopover = lazy(() =>
	import(
		/* webpackChunkName: "EncounterLevelAnnotationPopover" */ '../../../components/EncounterLevelAnnotation/EncounterLevelAnnotationPopover'
	)
);

const PatientLevelAnnotationPopover = lazy(() =>
	import(
		/* webpackChunkName: "PatientLevelAnnotationPopover" */ '../../../components/PatientLevelAnnotationPopover/PatientLevelAnnotationPopover'
	)
);
const PatientLevelOutcomeAnnotationPopover = lazy(() =>
	import(
		/* webpackChunkName: "PatientLevelOutcomeAnnotationPopover" */ '../../../components/PatientLevelOutcomeAnnotationPopover/PatientLevelOutcomeAnnotationPopover'
	)
);
const ModalPatientLevelAnnotation = lazy(() =>
	import(
		/* webpackChunkName: "ModalPatientLevelAnnotation" */ '../../../common/ModalPatientLevelAnnotation/ModalPatientLevelAnnotation'
	)
);
import dataIcon from '../../../images/data_icon.png';
import addPlusIcon from '../../../images/add_plus_icon.svg';
import removeMinusIcon from '../../../images/remove_minus_icon.svg';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DataPatientLevelAnnotation = (props) => {
	let isEditMode = sessionStorage.getItem('editModeDataPatientLevelAnnotation');
	const [doc_name, setDoc_name] = useState('');
	const [distinctCategory, setDistinctCategory] = useState([
		'Encounter-level annotation',
		'Episode-level annotation',
		'Patient-level annotation',
	]);

	const [modalOpened, setModalOpened] = useState(false);
	const [editMode, setEditMode] = useState(
		isEditMode && isEditMode.toString() === 'true' ? true : false
	);
	const [patientId, setPatientId] = useState(props.testProps ? props.testProps : 0);
	const [filterlist, setFilterlist] = useState([]);
	const [patientInfo, setPatientInfo] = useState('');
	const [tempPatientAnnotation, setTempPatientAnnotation] = useState([]);
	const [addedConcepts, setAddedConcepts] = useState([]);
	const [annotationListAll, setAnnotationListAll] = useState([]);
	const [patientAnnotation, setPatientAnnotation] = useState([]);
	const [deletedConcept, setDeletedConcept] = useState([]);
	const [patientOutcomeAnnotation, setPatientOutcomeAnnotation] = useState([]);
	const [tempPatientOutcomeAnnotation, setTempPatientOutcomeAnnotation] = useState([]);
	const [outcomeConceptUpdated, setOutcomeConceptUpdated] = useState(false);
	const [savePLAPopupMessage, setSavePLAPopupMessage] = useState('');
	const [selectedTab, setSelectedTab] = useState(1);
	const [encounterLevelAnnotation, setEncounterLevelAnnotation] = useState([]);
	const [annotationAttribute, setAnnotationAttribute] = useState('');
	const [requiredAnnotationAttribute, setRequiredAnnotationAttribute] = useState('');
	const [outcomeConcept, setOutcomeConcept] = useState([]);
	const confirmSavePopup = useRef();
	const messagePopup = useRef();

	useEffect(() => {
		setIsLoading(true);
		let { docId, doc_name } = props.match.params;
		getUserDetails().then((res) => {});
		getSubdocumentById(docId).then((resp) => {
			if (!window.testEnv || props.testProps) {
				setPatientId(resp.data.data.patient_id);
				getPatientList(resp.data.data.patient_id).then((res) => {
					setIsLoading(false);
					setPatientInfo(res.data.data[0]);
				});
				getEncounterLevelManualAnnotations(docId);
			}
		});
		let isEditMode = sessionStorage.getItem('editModeDataPatientLevelAnnotation');
		if (isEditMode && isEditMode.toString() === 'true') {
			setEditMode(true);
		}

		return () => {
			sessionStorage.removeItem('editModeDataPatientLevelAnnotation');
			sessionStorage.removeItem('deletedpatientAnnotation');
			sessionStorage.removeItem('tempPatientOutcomeAnnotation');
			sessionStorage.removeItem('updatedpatientOutcomeAnnotation');
			sessionStorage.removeItem('tempPatientAnnotation');
		};
	}, []);

	useEffect(() => {
		if (patientId) {
			getPatienLevelAnnotation(patientId);
		}
	}, [patientId]);

	useEffect(() => {
		setIsLoading(true);
		if (props?.redDocument?.descriptorOptions?.length == 0) {
			getDescriptorValues();
		}
		if (props?.redDocument?.progressionOptions?.length == 0) {
			getSeverityValues();
		}
		if (props?.redDocument?.severityOptions?.length == 0) {
			getProgressionValues();
		}

		getManualAnnotationProjectAttributes().then((resp) => {
			if (!props?.derivedAnnotationSource) {
				setRequiredAnnotationAttribute(resp?.data?.data || {});
				if (resp?.data?.data?.StudyId) {
					getConcepts(resp?.data?.data?.StudyId);
				}
			}
			setAnnotationAttribute(resp?.data?.data?.RequiredAnnotationAttribute || '');
			setIsLoading(false);
		});
	}, []);

	const getConcepts = (studyId) => {
		setIsLoading(true);
		getStudyOutcomes(studyId).then((resp) => {
			setIsLoading(false);
			if (resp.data.data) {
				let addedConcepts = [];
				addedConcepts = [
					...new Set(
						tempPatientOutcomeAnnotation.map((item) => {
							return item.ConceptId;
						})
					),
				];
				if (patientOutcomeAnnotation.length > 0) {
					patientOutcomeAnnotation.map((items) => {
						addedConcepts.push(items.ConceptId);
					});
				}
				let notAddedConcept = resp.data.data.filter((item) => {
					if (addedConcepts.indexOf(item.ConceptId) == -1) {
						return item;
					}
				});
				setOutcomeConcept(notAddedConcept);
			}
		});
	};

	const getEncounterLevelManualAnnotations = (docId) => {
		getEncounterLevelManualAnnotation(docId).then((resp) => {
			if (!window.testEnv) {
				setEncounterLevelAnnotation(resp.data);
			}
		});
	};

	const getPatienLevelAnnotation = (patientId) => {
		if (!patientId) {
			patientId = patientInfo.patient_id;
		}
		getPatientManualAnnotationsData(patientId, 'PATIENT').then((annotations) => {
			if (annotations) {
				setIsLoading(false);
				let deletedpatientAnnotation = sessionStorage.getItem('deletedpatientAnnotation');

				if (deletedpatientAnnotation) {
					let deletedConcepts = JSON.parse(deletedpatientAnnotation);
					let filterredAnnotation = annotations.filter((items) => {
						return deletedConcepts.indexOf(items.ConceptId) == -1;
					});

					setDeletedConcept(deletedConcepts);
					setPatientAnnotation(filterredAnnotation || []);
				} else {
					setPatientAnnotation(annotations || []);
				}
			}
		});
		getPatientManualAnnotationsData(patientId, 'TEXT').then((annotations, index) => {
			setIsLoading(false);
			setAnnotationListAll(annotations || []);
		});
		getDerivedManualAnnotations();
	};

	const updateAnnotationList = (list, outcomeConcepts) => {
		let temp = tempPatientAnnotation;
		list.map((items) => {
			temp.push(items);
		});

		setPatientAnnotation(temp);
		setTempPatientOutcomeAnnotation(outcomeConcepts);

		sessionStorage.setItem('tempPatientAnnotation', JSON.stringify(temp));
		sessionStorage.setItem('tempPatientOutcomeAnnotation', JSON.stringify(outcomeConcepts));
	};

	const handleClose = () => {
		setModalOpened(false);
		//SET CLOSE MODAL IN REDUX
		openModal('');
	};
	const addAnnotation = () => {
		let addedConcept = [];
		if (patientAnnotation && patientAnnotation.length > 0) {
			patientAnnotation.map((item) => {
				addedConcept.push(item.ConceptId);
			});
		}
		if (tempPatientAnnotation.length > 0) {
			tempPatientAnnotation.map((item) => {
				addedConcept.push(item.ConceptId);
			});
		}

		setModalOpened(!modalOpened);
		setAddedConcepts(addedConcept);
	};

	const getPatientManualAnnotations = () => {
		setIsLoading(true);

		getPatientManualAnnotationsData(patientId, 'PATIENT').then((annotations) => {
			setIsLoading(false);
			setPatientAnnotation(annotations || []);
			setTempPatientAnnotation([]);
			setDeletedConcept([]);
			setEditMode(false);
		});
	};

	const getDerivedManualAnnotations = () => {
		setIsLoading(true);
		let isEditMode = sessionStorage.getItem('editModeDataPatientLevelAnnotation');
		getDerivedManualAnnotationsData(0, patientId, 'Outcome').then((resp, index) => {
			setIsLoading(false);
			setPatientOutcomeAnnotation(resp.data.data || []);
			setTempPatientOutcomeAnnotation([]);
			setEditMode(isEditMode && isEditMode.toString() === 'true' ? true : false);

			let deletedpatientAnnotation = sessionStorage.getItem('deletedpatientAnnotation');
			if (deletedpatientAnnotation) {
				setDeletedConcept(JSON.parse(deletedpatientAnnotation));
			} else {
				setDeletedConcept([]);
			}
			let tempPatientAnnotations = sessionStorage.getItem('tempPatientAnnotation');
			if (tempPatientAnnotations) {
				setTempPatientAnnotation(JSON.parse(tempPatientAnnotations));
			}
			let tempPatientOutcomeAnnotations = sessionStorage.getItem(
				'tempPatientOutcomeAnnotation'
			);
			if (tempPatientOutcomeAnnotations) {
				setTempPatientOutcomeAnnotation(JSON.parse(tempPatientOutcomeAnnotations));
			}
			let updatedpatientOutcomeAnnotation = sessionStorage.getItem(
				'updatedpatientOutcomeAnnotation'
			);
			if (updatedpatientOutcomeAnnotation) {
				setPatientOutcomeAnnotation(JSON.parse(updatedpatientOutcomeAnnotation));
			}
		});
	};

	const editModeMethod = () => {
		sessionStorage.setItem('editModeDataPatientLevelAnnotation', true);
		setEditMode(true);
	};

	const exitEditMode = (force) => {
		if (force) {
			setEditMode(false);
			setTempPatientAnnotation([]);
			setDeletedConcept([]);
			setTempPatientOutcomeAnnotation([]);

			sessionStorage.removeItem('editModeDataPatientLevelAnnotation');
			sessionStorage.removeItem('deletedpatientAnnotation');
			sessionStorage.removeItem('tempPatientAnnotation');
			sessionStorage.removeItem('tempPatientOutcomeAnnotation');
			sessionStorage.removeItem('updatedpatientOutcomeAnnotation');
			getPatienLevelAnnotation();
		} else {
			if (
				deletedConcept.length > 0 ||
				tempPatientAnnotation.length > 0 ||
				tempPatientOutcomeAnnotation.length > 0
			) {
				messagePopup.current.handleClick();
			} else {
				setEditMode(false);
				setTempPatientAnnotation([]);
				setDeletedConcept([]);
				setTempPatientOutcomeAnnotation([]);

				sessionStorage.removeItem('editModeDataPatientLevelAnnotation');
				sessionStorage.removeItem('deletedpatientAnnotation');
				sessionStorage.removeItem('tempPatientAnnotation');
				sessionStorage.removeItem('tempPatientOutcomeAnnotation');
				sessionStorage.removeItem('updatedpatientOutcomeAnnotation');
			}
		}
	};

	const deletePatientAnnotation = (conceptId) => {
		let temp = patientAnnotation.filter((item, index) => {
			return item.ConceptId != conceptId;
		});
		let conceptIds = deletedConcept;
		conceptIds.push(conceptId);

		setPatientAnnotation(temp);
		setDeletedConcept(conceptIds);

		sessionStorage.setItem('deletedpatientAnnotation', JSON.stringify(conceptIds));
	};

	const deletePatientOutcomeAnnotation = (conceptId) => {
		let temp = patientOutcomeAnnotation.filter((item, index) => {
			return item.ConceptId != conceptId;
		});
		let conceptIds = deletedConcept;
		conceptIds.push(conceptId);

		setPatientOutcomeAnnotation(temp);
		setDeletedConcept(conceptIds);

		sessionStorage.setItem('updatedpatientOutcomeAnnotation', JSON.stringify(temp));
	};

	const deleteTempPatientAnnotation = (concept_index) => {
		let temp = tempPatientAnnotation.filter((item, index) => {
			return concept_index != index;
		});

		setTempPatientAnnotation(temp);

		sessionStorage.setItem('tempPatientAnnotation', JSON.stringify(temp));
	};

	const deleteTempPatientOutcomeAnnotation = (concept_index) => {
		let temp = tempPatientOutcomeAnnotation.filter((item, index) => {
			return concept_index != index;
		});

		setTempPatientOutcomeAnnotation(temp);

		sessionStorage.setItem('tempPatientOutcomeAnnotation', JSON.stringify(temp));
	};

	const gotoPatientDetail = () => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/patient/${patientId}`);
	};

	const redirectToAnnotation = (items) => {
		if (!editMode) {
			let manualAnnotationId = `route_annotation_id=${items.AnnotationId}`;
			props.history.push(
				`${constants.ROUTE_URL 
					}/dashboard/document/${ 
					items.SubdocumentId 
					}?${ 
					manualAnnotationId}`
			);
		}
	};

	const updateOutcomeAnnotation = (updatedAnnotation, isTemp) => {
		setOutcomeConceptUpdated(true);
		if (isTemp) {
			let temp = patientOutcomeAnnotation;
			let updatedOutcome = temp.map((items) => {
				if (updatedAnnotation.ConceptId == items.ConceptId) {
					items.NumericResult = updatedAnnotation.NumericResult;
					items.Descriptor = updatedAnnotation.Descriptor;
					items.Severity = updatedAnnotation.Severity;
					items.Progression = updatedAnnotation.Progression;
				}
				return items;
			});

			setPatientOutcomeAnnotation(updatedOutcome);

			sessionStorage.setItem(
				'updatedpatientOutcomeAnnotation',
				JSON.stringify(updatedOutcome)
			);
		} else {
			let temp = tempPatientOutcomeAnnotation;
			let updatedOutcome = temp.map((items) => {
				if (updatedAnnotation.ConceptId == items.ConceptId) {
					items.NumericResult = updatedAnnotation.NumericResult;
					items.Descriptor = updatedAnnotation.Descriptor;
					items.Severity = updatedAnnotation.Severity;
					items.Progression = updatedAnnotation.Progression;
				}
				return items;
			});
			setTempPatientOutcomeAnnotation(updatedOutcome);

			sessionStorage.setItem('tempPatientOutcomeAnnotation', JSON.stringify(updatedOutcome));
		}
	};

	const saveAnnotation = () => {
		let addedConcepts = [];
		sessionStorage.removeItem('editModeDataPatientLevelAnnotation');
		sessionStorage.removeItem('deletedpatientAnnotation');
		sessionStorage.removeItem('tempPatientOutcomeAnnotation');
		sessionStorage.removeItem('updatedpatientOutcomeAnnotation');
		sessionStorage.removeItem('tempPatientAnnotation');
		if (tempPatientAnnotation.length > 0) {
			tempPatientAnnotation.map((item) => {
				addedConcepts.push(item.ConceptId);
			});
		}

		if (deletedConcept.length > 0) {
			DeletePatientManualAnnotations(patientId, deletedConcept.join(',')).then(
				(annotations) => {
					getPatientManualAnnotations();
				}
			);
		}
		if (tempPatientAnnotation.length > 0) {
			AddPatientManualAnnotation(patientId, addedConcepts.join(',')).then((annotations) => {
				getPatientManualAnnotations();
			});
		}

		if (outcomeConceptUpdated || tempPatientOutcomeAnnotation.length > 0) {
			let resquestBody = [];
			if (outcomeConceptUpdated) {
				patientOutcomeAnnotation.map((items) => {
					if (deletedConcept.indexOf(items.ConceptId) == -1) {
						resquestBody.push(items);
					}
				});
			}
			if (tempPatientOutcomeAnnotation.length > 0) {
				let temp = tempPatientOutcomeAnnotation;
				temp.map((item, index) => {
					resquestBody.push({
						DerivedAnnotationId: 0,
						PatientId: patientId,
						AnnotationLevel: 'Patient',
						AnnotationSource: 'Outcome',
						ConceptId: item.ConceptId,
						Descriptor: item.Descriptor,
						NumericResult: item.NumericResult,
						Progression: item.Progression,
						Severity: item.Severity,
						DerivedAnnotationConceptAttributeId: 0,
					});
				});
			}

			addDerivedAnnotation(resquestBody).then((annotations) => {
				getDerivedManualAnnotations();
			});
		}

		// props.history.push(
		// 	constants.ROUTE_URL + '/dashboard/patientlevelannotation/' + props.match.params.docId
		// );
	};

	const rootClosexit = () => {};

	const checkAnnotationsAdded = () => {
		if (outcomeConceptUpdated || deletedConcept.length > 0) {
			setSavePLAPopupMessage('Are you sure you want to save revision in annotations?');
		} else if (tempPatientOutcomeAnnotation.length > 0 || tempPatientAnnotation.length > 0) {
			setSavePLAPopupMessage(
				'Initial annotations will be locked for calculating inter-rater reliability. <br />Any subsequent changes will be considered revisions.'
			);
		} else {
			setSavePLAPopupMessage('Please add/update a concept.');
		}
		confirmSavePopup?.current?.handleClick();
	};

	const savePLAAnnotation = () => {
		if (outcomeConceptUpdated || deletedConcept.length > 0) {
			saveAnnotation();
		} else if (tempPatientOutcomeAnnotation.length > 0 || tempPatientAnnotation.length > 0) {
			saveAnnotation();
		}
	};

	let { loading } = props.loading;
	let { user_id } = props.redUsermanagement.loggedInUser;

	return (
		<div className="DataAutomated">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}

			<HeaderTitles
				data={headerData}
				editMode={editModeMethod}
				search={false}
				history={props.history}
				match={props.match}
			/>
			<div className="document-header-container">
				<span>Derived manual annotations: </span>
				{props.redSidenav?.userRole?.Role == roleAccess.ANNOTATOR ? (
					<span className={''}>{patientInfo.patient_Identifier}</span>
				) : (
					<Link
						to={`${constants.ROUTE_URL}/dashboard/patient/${patientId}`}
						onClick={gotoPatientDetail}
					>
						{patientInfo.patient_Identifier}
					</Link>
				)}
			</div>
			<div className="section-header">Encounter-level annotations</div>
			<ul className="section-container">
				{encounterLevelAnnotation ? (
					<li>
						<Suspense fallback={<div className="defaultSpinner" />}>
							<EncounterLevelAnnotationPopover
								annotation={encounterLevelAnnotation}
							/>
						</Suspense>
					</li>
				) : (
					<li />
				)}
			</ul>
			{editMode && (
				<div className="add-row" style={{ paddingLeft: '16px' }}>
					<img
						src={addPlusIcon}
						// onClick={addAnnotation}
						className="App-plusicon hyperlink"
						alt="plusicon"
					/>
				</div>
			)}
			<div className="section-header">Episode-level annotations</div>
			<ul className="section-container">
				<li />
			</ul>
			{editMode && (
				<div className="add-row" style={{ paddingLeft: '16px' }}>
					<img
						src={addPlusIcon}
						// onClick={addAnnotation}
						className="App-plusicon hyperlink"
						alt="plusicon"
					/>
				</div>
			)}
			<div className="section-header">Patient-level annotations</div>
			<ul className="section-container">
				{patientAnnotation &&
					patientAnnotation.length > 0 &&
					Array.isArray(patientAnnotation) &&
					patientAnnotation.map((annotationceptCon) => (
						<li>
							{editMode && (
								<img
									src={removeMinusIcon}
									onClick={() =>
										deletePatientAnnotation(annotationceptCon.ConceptId)
									}
									className="App-minusIcon hyperlink"
									alt="minusicon"
								/>
							)}
							<Suspense fallback={<div className="defaultSpinner" />}>
								<PatientLevelAnnotationPopover
									annotation={annotationceptCon}
									redirectToAnnotation={redirectToAnnotation}
								/>
							</Suspense>
						</li>
					))}
				{tempPatientAnnotation &&
					tempPatientAnnotation.length > 0 &&
					tempPatientAnnotation.map((annotationceptCon, index) => (
						<li>
							{editMode && (
								<img
									src={removeMinusIcon}
									onClick={() => deleteTempPatientAnnotation(index)}
									className="App-minusIcon hyperlink"
									alt="minusicon"
								/>
							)}
							<Suspense fallback={<div className="defaultSpinner" />}>
								<PatientLevelAnnotationPopover
									annotation={annotationceptCon}
									redirectToAnnotation={redirectToAnnotation}
								/>
							</Suspense>
						</li>
					))}
				{patientOutcomeAnnotation &&
					patientOutcomeAnnotation.map((outcomeConcepts, index) => (
						<li>
							{editMode && (
								<img
									src={removeMinusIcon}
									onClick={() =>
										deletePatientOutcomeAnnotation(outcomeConcepts.ConceptId)
									}
									className="App-minusIcon hyperlink"
									alt="minusicon"
								/>
							)}
							<Suspense fallback={<div className="defaultSpinner" />}>
								<PatientLevelOutcomeAnnotationPopover
									patientId={patientId}
									annotation={outcomeConcepts}
									editMode={editMode}
									isTemp={false}
									userId={outcomeConcepts.UserId}
									updateOutcomeAnnotation={updateOutcomeAnnotation}
									requiredAnnotationAttribute={annotationAttribute}
								/>
							</Suspense>
						</li>
					))}
				{tempPatientOutcomeAnnotation &&
					tempPatientOutcomeAnnotation.map((outcomeConcepts, index) => (
						<li>
							{editMode && (
								<img
									src={removeMinusIcon}
									onClick={() => deleteTempPatientOutcomeAnnotation(index)}
									className="App-minusIcon hyperlink"
									alt="minusicon"
								/>
							)}
							<Suspense fallback={<div className="defaultSpinner" />}>
								<PatientLevelOutcomeAnnotationPopover
									patientId={patientId}
									annotation={outcomeConcepts}
									editMode={editMode}
									isTemp={true}
									userId={user_id}
									updateOutcomeAnnotation={updateOutcomeAnnotation}
									requiredAnnotationAttribute={annotationAttribute}
								/>
							</Suspense>
						</li>
					))}
			</ul>
			{editMode && (
				<div className="add-row" style={{ paddingLeft: '16px' }}>
					<img
						src={addPlusIcon}
						onClick={addAnnotation}
						className="App-plusicon hyperlink"
						alt="plusicon"
					/>
				</div>
			)}

			{modalOpened && (
				<Suspense fallback={<div className="defaultSpinner" />}>
					<ModalPatientLevelAnnotation
						open={modalOpened}
						handleClose={handleClose}
						addedConcepts={addedConcepts}
						annotationList={annotationListAll}
						updateAnnotationList={updateAnnotationList}
						tempPatientOutcomeAnnotation={tempPatientOutcomeAnnotation}
						patientOutcomeAnnotation={patientOutcomeAnnotation}
						manualAnnotationProjectAttributes={requiredAnnotationAttribute}
						outcomeConcept={outcomeConcept}
					/>
				</Suspense>
			)}

			{editMode && (
				<div className="col-sm-6 text-right trialButton addNewTrial">
					<button type="button" className="trialArm" onClick={() => exitEditMode()}>
						Cancel
					</button>

					<button type="button" className="trialArm" onClick={checkAnnotationsAdded}>
						Save
					</button>
				</div>
			)}
			<MessagePopup
				rootClosexit={rootClosexit}
				open={(c) => (messagePopup.current = c)}
				text={'Are you sure, your changes will be lost on ok click?'}
				confirmClick={() => exitEditMode(true)}
			/>
			<SaveConfirm
				rootClosexit={rootClosexit}
				open={(c) => (confirmSavePopup.current = c)}
				//openPopup={openPopup}
				text={savePLAPopupMessage}
				confirmClick={savePLAAnnotation}
			/>
			<Copyright />
		</div>
	);
};

DataPatientLevelAnnotation.propTypes = {
	derivedAnnotationSource: PropTypes.any,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
			doc_name: PropTypes.any,
		}),
	}),
	redDocument: PropTypes.shape({
		descriptorOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		progressionOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
		severityOptions: PropTypes.shape({
			length: PropTypes.number,
		}),
	}),
	redSidenav: PropTypes.shape({
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
	redUsermanagement: PropTypes.shape({
		loggedInUser: PropTypes.shape({
			user_id: PropTypes.any,
		}),
	}),
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(DataPatientLevelAnnotation));
