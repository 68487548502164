import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import constants from '../../constants';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { apiGet } from '../../utilities/utils';
import { setHistory } from '../../common/FilterFunctions/FilterFunctions';
import { searchConceptsById } from './manualTooltipScrollHelper';
import manualAnnotationIcon from '../../images/annotationicon_45_x_45.svg';
/**
 * THIS COMPONENT DISPLAY MANUAL ANNOTATION FOR PATIENTS, DOCUMENTS AND COHORTS
 * IN POPUP WITH INFINITE SCROLL FEATURE.
 */
const ManualTooltipScroll = (props) => {
	let pageSize = 10;
	const [displayList, setDisplayList] = useState(
		props.displayListProps ? props.displayListProps : []
	);
	const [currentPage, setCurrentPage] = useState(1);
	const [fetching, setFetching] = useState(false);
	const [totalFetched, setTotalFetched] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [popoverBottom, setpopoverBottom] = useState();

	useEffect(() => {
		setDisplayList(props.displayListProps ? props.displayListProps : []);
	}, []);

	useEffect(() => {
		const _popoverBottom = (
			<Popover id="manual-annotation-popover">
				<Popover.Title>
					<div style={{ fontSize: 22, paddingLeft: 2, paddingBottom: 2 }}>
						Manual annotation list
					</div>
				</Popover.Title>
				<Popover.Content>
					<div
						id="manual-annotation-popover-content"
						style={{ height: 180, overflow: 'auto' }}
						onScroll={handleScroll}
					>
						<ul>
							{displayList.map((mAnnotation, index) => {
								return (
									<li key={index} style={{ padding: 8, paddingLeft: 24 }}>
										<span
											className="hyperlink"
											onClick={() =>
												goToDataDetail(
													mAnnotation.subdocument_id,
													mAnnotation.concept_id,
													mAnnotation.annotation_id
												)
											}
										>
											{' '}
											{!mAnnotation.is_experienced ? 'No' : ''}{' '}
											{mAnnotation.display_name}
										</span>
										<span style={{ marginLeft: 4 }}>
											({mAnnotation.display_name_code_type})
										</span>
									</li>
								);
							})}
						</ul>
						{fetching && displayList.length > 1 && (
							<div
								style={{ position: 'absolute', top: '84%', left: '42%' }}
								className="defaultSpinner"
							/>
						)}
					</div>
					{displayList.length < 1 && (
						<div
							style={{ position: 'absolute', top: '45%', left: '42%' }}
							className="defaultSpinner"
						/>
					)}
				</Popover.Content>
			</Popover>
		);
		setpopoverBottom(_popoverBottom);
	}, [displayList]);

	/*************************************
	 * Function Name: getApiEndpoint
	 * Description: Determines API endpoint and required parameters.
	 * @param - type - Annotation type
	 *************************************/
	const getApiEndpoint = (type) => {
		if (type === 'patient') {
			return {
				api: 'GetPatientManualAnnotations',
				param: { m_PatientId: props.patientId },
			};
		}
		if (type === 'document') {
			return {
				api: 'GetDocumentManualAnnotations',
				param: { m_DocIdList: props.documentList },
			};
		}
		if (type === 'cohort') {
			return {
				api: 'GetCohortManualAnnotations',
				param: { m_CohortId: props.cohortId },
			};
		}
		return '';
	};
	/********************************
	 * Function Name: handleScroll
	 * Description: This function watch for scroll event and fire http request when
	 * content division scrolled more than 75 percent.
	 * @param - event - Event object
	 ********************************/
	const handleScroll = (event) => {
		const element = event.target;
		const scrolledPercent =
			((element.scrollTop + element.clientHeight) / element.scrollHeight) * 100;

		if (scrolledPercent > 75) {
			getMAnnotations(true);
		}
	};

	/*********************************
	 * Function Name: getMAnnotations
	 * Description: Fetch mannual annotations and set in local state variable.
	 *********************************/
	const getMAnnotations = (scrolled) => {
		const apiParam = getApiEndpoint(props.annotationType);
		let apiEndpoint = apiParam.api;
		let queryParam = apiParam.param;

		// let queryParam = {};
		if (!scrolled && !props.testPropsScroll) { // testPropsScroll custom props for jest
			setDisplayList([]);
			setCurrentPage(1);
			queryParam = {
				...queryParam,
				m_DocLevelManAnnotation: false,
				pageNumber: 1,
				pageSize: pageSize,
			};
		} else {
			queryParam = {
				...queryParam,
				m_DocLevelManAnnotation: false,
				pageNumber: currentPage,
				pageSize: pageSize,
			};
			if (totalFetched || fetching || !apiEndpoint) {
				//IF CURRENT API REQUEST IS PENDING OR ALL PAGES HAS BEEN FETCHED, NO NEED TO PROCEED FURTHER.
				return;
			}
		}

		//DISABLE FURTHER REQUESTS UNTIL CURRENT REQUEST GETS RESOLVED.
		setFetching(true);
		apiGet(apiEndpoint, queryParam).then((annoResponse) => {
			//SET TOTAL PAGE SIZE, BECAUSE API RETURN PAGE-SIZE ONLY FOR PAGE = 1
			if (annoResponse.data.manAnnCount) {
				setTotalPages(Math.ceil(annoResponse.data.manAnnCount / pageSize));
			}
			setFetching(false);
			const manAnnotations = annoResponse.data.data;

			//MAKE COMMA SEPERATED LIST OF MANUAL ANNOTATIONS
			const conceptIdList = manAnnotations.map((ann) => ann.concept_id);
			if (!conceptIdList) {
				return;
			}
			//GET CONCEPTS BASED ON MANUAL ANNOTATIONS LIST
			searchConceptsById(conceptIdList).then((criteriaConcepts) => {
				//MAP CONCEPTS TO ANNOTATION LIST
				const annotationList = manAnnotations.map((annotation) => {
					const found = criteriaConcepts.find(
						(concept) => concept.cuid === annotation.concept_id
					);
					if (found) {
						return {
							subdocument_id: annotation.subdocument_id,
							annotation_id: annotation.annotation_id,
							is_experienced: annotation.is_experienced,
							display_name: found.text,
							display_name_code_type: found.sab,
						};
					}
				});
				setDisplayList([...displayList, ...annotationList]);
				setCurrentPage(currentPage + 1);
				setTotalFetched(currentPage === totalPages);
			});
		});
	};

	const goToDataDetail = (document_id, concept_id, annotation_id) => {
		setHistory(false, props.historyText || '');
		if (props.goToDataDetail) {
			props.goToDataDetail(document_id, concept_id, annotation_id);
		}
	};

	return (
		<OverlayTrigger
			trigger="click"
			rootClose={true}
			placement="auto"
			overlay={popoverBottom}
			onEnter={() => getMAnnotations()}
			onClick={() => getMAnnotations()}
		>
			<sup>
				<img
					src={manualAnnotationIcon}
					className="manualPopover"
					style={{ width: '14px', height: '14px' }}
				/>
			</sup>
		</OverlayTrigger>
	);
};

ManualTooltipScroll.propTypes = {
	annotationType: PropTypes.any,
	cohortId: PropTypes.any,
	displayListProps: PropTypes.any,
	documentList: PropTypes.any,
	goToDataDetail: PropTypes.func,
	historyText: PropTypes.string,
	patientId: PropTypes.any,
};

export default ManualTooltipScroll;
