import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import constants from '../../../constants';
import actions from '../../../actions';
import Loading from 'react-loading-spinner';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Copyright from '../../../components/Copyright/Copyright';
import { Link } from 'react-router-dom';
import {
	setIsLoading,
	updateStudyValidation,
	setSideNavList,
	setHistory,
	getStudyDetail,
	getEncounterTwoClassEncounterDetails,
} from './StudyValidityDrilldownDetailsHelper';
import Dropdown from 'react-bootstrap/Dropdown';
import trialIcon from '../../../images/trial_icon.png';
import { setObjectToLocal, getObjectFromLocal } from '../../../utilities/utils';

const headerData = {
	title: 'Validity',
	list: 'detail',
	img: <img src={trialIcon} className={'App-TrialList'} alt={'Trial List'} />,
};
const selectedHeader = getObjectFromLocal('studyValidityHeaderSelection');
let header = [];
if (selectedHeader) {
	header = selectedHeader || [];
} else {
	header = [
		{ name: 'present', checked: true },
		{ name: 'experienced', checked: true },
		{ name: 'definitive', checked: true },
		{ name: 'negated', checked: true },
		{ name: 'subject', checked: true },
		{ name: 'current', checked: true },
		{ name: 'severity', checked: true },
		{ name: 'descriptor', checked: true },
		{ name: 'result', checked: true },
	];
}

const StudyValidityDrilldownDetails = (props) => {
	const {
		studyId,
		conceptId,
		encounterId,
		datatype,
		concept_name,
		is_event,
		att_name,
		att_value,
	} = props.match.params;
	let criteria = actions.getStudyValidationCriteria(studyId);
	let subTitle = (criteria.column && criteria.column.label) || 'Accuracy';
	const { studyDetail } = props.study;
	let { loading } = props.loading;
	const [drilldownDetailsData, setDrilldownDetailsData] = useState([]);
	const [commonHeader, setCommonHeader] = useState(header);
	const [dropDownShow, setDropDownShow] = useState(false);
	const [pageNo, setPageNo] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(10);

	useEffect(() => {
		updateStudyValidation([]);
		setSideNavList([]);
		getStudyDetail(studyId).then((studyDetail) => {
			let label = 'Concept';
			let filterLabel = {
				page: 'studyvalidity',
				options: [
					{
						filterLabel: 'Study',
						name: studyDetail?.study_name,
						fixed: true,
					},
					{
						filterLabel: label,
						name: concept_name?.split('-').join(' '),
					},
				],
			};
			setFilterLabel(filterLabel);
		});

		setTableData();
	}, []);

	useEffect(() => {
		setTableData();
	}, [pageNo]);

	const setTableData = () => {
		setIsLoading(true);
		getEncounterTwoClassEncounterDetails(
			conceptId,
			encounterId,
			datatype,
			is_event,
			att_name,
			att_value
		)
			.then((res) => {
				setDrilldownDetailsData(res?.data || []);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
			});
	};

	const gotoPatientDocument = (isPatient, data) => {
		if (isPatient) {
			setHistory({
				...window.location,
				display_name: `Study detail: ${studyDetail.study_name}`,
			});
		} else {
			setHistory({
				...window.location,
				display_name: `Study detail: ${studyDetail.study_name}`,
			});
			setQueryParams({
				pathname: `${constants.ROUTE_URL}/dashboard/document/${data.doc_id}`,
				query: {
					doc_name: data.doc_name,
				},
			});
		}
	};

	const redirectToManulAnnotation = (items) => {
		let id = `route_annotation_id=${items.manual_annotation_id}`;
		let url = '';
		if (items.manual_annotation_id) {
			url = `${constants.ROUTE_URL}/dashboard/document/${items.subdocument_id}?${id}`;
		} else if (items.narrative_encounter_id) {
			url = `${constants.ROUTE_URL}/dashboard/document/${items.subdocument_id}?${id}`;
		} else {
			url = `${constants.ROUTE_URL}/dashboard/document/${items.subdocument_id}`;
		}
		return url;
	};

	const redirectToAutoAnnotation = (items) => {
		let url = '';
		let id =
			`automated_annotation_id=${ 
			items.auto_section_number 
			}_${ 
			items.auto_start_position 
			}_${ 
			items.auto_end_position}`;

		if (id.includes('null')) {
			if (datatype === 'Unstructured' && items.narrative_encounter_id) {
				url = `${constants.ROUTE_URL}/dashboard/document/${items.narrative_encounter_id}`;
			} else if (datatype === 'Structured' && items.discrete_encounter_id) {
				url = `${constants.ROUTE_URL}/dashboard/document/${items.discrete_encounter_id}`;
			} else {
				url = `${constants.ROUTE_URL}/dashboard/document/${items.subdocument_id}`;
			}
		} else {
			url = `${constants.ROUTE_URL}/dashboard/document/${items.subdocument_id}?${id}`;
		}

		return url;
	};

	const getDocumentUrl = (subdocument_id) => {
		return `${constants.ROUTE_URL}/dashboard/document/${subdocument_id}`;
	};

	const TableHeader = () => {
		let header = (
			<tr>
				<td>
					<div className="first">
						<span>Patients</span>
					</div>
				</td>
				<td>
					<div className="first">
						<span>Encounters</span>
					</div>
				</td>
				<Fragment>
					{commonHeader.map(function (item, i) {
						return (
							<Fragment>
								{item.checked && (
									<td key={i}>
										<div>
											<span>{`${datatype} ${item.name}`}</span>
										</div>
									</td>
								)}
							</Fragment>
						);
					})}
				</Fragment>
				<Fragment>
					{commonHeader.map(function (item, i) {
						return (
							<Fragment>
								{item.checked && (
									<td key={i}>
										<div>
											<span>{`Reference ${item.name}`}</span>
										</div>
									</td>
								)}
							</Fragment>
						);
					})}
				</Fragment>
			</tr>
		);
		return header;
	};

	const camelCase = (str) => {
		if (isNaN(str) && str) {
			const str2 = str.toLowerCase();
			return str2.charAt(0).toUpperCase() + str2.slice(1);
		}
		return str;
	};

	const renderEncounter = (items) => {
		if (items.subdocument_id) {
			return (
				<div className="first">
					<Link to={getDocumentUrl(items.subdocument_id)}>
						{items.encounter_name || items.subdocument_id}
					</Link>
				</div>
			);
		} else {
			return (
				<div className="first">
					<div>{items.encounter_name || items.subdocument_id}</div>
				</div>
			);
		}
	};

	const renderPresentColumn = (items) => {
		if (items?.auto_present == 'NA') return 'NA';
		if (items?.auto_present == null) return '';
		if (datatype == 'Structured') {
			if (items?.auto_present == false && !items.discrete_encounter_id) return 'False';
		}

		return (
			<Link to={redirectToAutoAnnotation(items)}>
				{camelCase(JSON.stringify(items?.auto_present))}
			</Link>
		);
	};

	const TableBody = () => {
		let body = (
			<Fragment>
				{drilldownDetailsData.map((items, index) => (
					<tr key={index}>
						<td>
							<div className="first">
								{items.patient_identifier !== 'NA' ? (
									<Link
										className="hyperlink"
										onClick={() => {
											gotoPatientDocument(true, items);
										}}
										to={
											`${constants.ROUTE_URL 
											}/dashboard/patient/${ 
											items.patient_id}`
										}
										id="testid-link-4"
									>
										{items.patient_identifier || 0}
									</Link>
								) : (
									'NA'
								)}
							</div>
						</td>
						<td>{items.encounter_name !== 'NA' ? renderEncounter(items) : 'NA'}</td>
						<Fragment>
							{commonHeader[0].checked && (
								<td>
									<div className="text-center">{renderPresentColumn(items)}</div>
									{/* Structured | Unstructured | Inferred: Present */}
								</td>
							)}
							{commonHeader[1].checked && (
								<td>
									<div className="text-center">
										{items?.auto_experienced === 'NA' ? (
											'NA'
										) : items?.auto_experienced !== null ? (
											<Link to={redirectToAutoAnnotation(items)}>
												{camelCase(JSON.stringify(items?.auto_experienced))}
											</Link>
										) : (
											''
										)}
									</div>
									{/* Unstructured: Experienced */}
								</td>
							)}
							{commonHeader[2].checked && (
								<td>
									<div className="text-center">
										{items?.auto_definitive === 'NA' ? (
											'NA'
										) : items?.auto_definitive !== null ? (
											<Link to={redirectToAutoAnnotation(items)}>
												{camelCase(JSON.stringify(items?.auto_definitive))}
											</Link>
										) : (
											''
										)}
									</div>
									{/* Unstructured: Definitive */}
								</td>
							)}
							{commonHeader[3].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.auto_negated === 'NA' ? (
												'NA'
											) : items?.auto_negated !== null ? (
												<Link to={redirectToAutoAnnotation(items)}>
													{camelCase(JSON.stringify(items?.auto_negated))}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Unstructured: Negated */}
									</div>
								</td>
							)}
							{commonHeader[4].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.auto_negated === 'NA' ? (
												'NA'
											) : items?.auto_subject !== null ? (
												<Link to={redirectToAutoAnnotation(items)}>
													{camelCase(items?.auto_subject)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Unstructured: Subject */}
									</div>
								</td>
							)}
							{commonHeader[5].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.auto_current === 'NA' ? (
												'NA'
											) : items?.auto_current !== null ? (
												<Link to={redirectToAutoAnnotation(items)}>
													{camelCase(JSON.stringify(items?.auto_current))}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Unstructured: Current */}
									</div>
								</td>
							)}
							{commonHeader[6].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.auto_severity === 'NA' ? (
												'NA'
											) : items?.auto_severity !== null ? (
												<Link to={redirectToAutoAnnotation(items)}>
													{camelCase(items?.auto_severity)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Unstructured: Severity */}
									</div>
								</td>
							)}
							{commonHeader[7].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.auto_descriptor === 'NA' ? (
												'NA'
											) : items?.auto_descriptor !== null ? (
												<Link to={redirectToAutoAnnotation(items)}>
													{camelCase(items?.auto_descriptor)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Unstructured: Descriptor */}
									</div>
								</td>
							)}
							{commonHeader[8].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.auto_result === 'NA' ? (
												'NA'
											) : items?.auto_result !== null ? (
												<Link to={redirectToAutoAnnotation(items)}>
													{camelCase(JSON.stringify(items?.auto_result))}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Unstructured: Result */}
									</div>
								</td>
							)}
						</Fragment>
						<Fragment>
							{commonHeader[0].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_present === 'NA' ? (
												'NA'
											) : items?.manual_present !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(
														JSON.stringify(items?.manual_present)
													)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Present */}
									</div>
								</td>
							)}
							{commonHeader[1].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_experienced === 'NA' ? (
												'NA'
											) : items?.manual_experienced !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(
														JSON.stringify(items?.manual_experienced)
													)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Experienced */}
									</div>
								</td>
							)}
							{commonHeader[2].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_definitive === 'NA' ? (
												'NA'
											) : items?.manual_definitive !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(
														JSON.stringify(items?.manual_definitive)
													)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Definitive */}
									</div>
								</td>
							)}
							{commonHeader[3].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_negated === 'NA' ? (
												'NA'
											) : items?.manual_negated !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(
														JSON.stringify(items?.manual_negated)
													)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Negated */}
									</div>
								</td>
							)}
							{commonHeader[4].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_subject === 'NA' ? (
												'NA'
											) : (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(items?.manual_subject)}
												</Link>
											)}
										</div>
										{/* Reference: Subject */}
									</div>
								</td>
							)}
							{commonHeader[5].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_current === 'NA' ? (
												'NA'
											) : items?.manual_current !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(
														JSON.stringify(items?.manual_current)
													)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Current */}
									</div>
								</td>
							)}
							{commonHeader[6].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_severity === 'NA' ? (
												'NA'
											) : items?.manual_severity !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(items?.manual_severity)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Severity */}
									</div>
								</td>
							)}
							{commonHeader[7].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_descriptor === 'NA' ? (
												'NA'
											) : items?.manual_descriptor !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(items?.manual_descriptor)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Descriptor */}
									</div>
								</td>
							)}
							{commonHeader[8].checked && (
								<td>
									<div>
										<div className="text-center">
											{items?.manual_result === 'NA' ? (
												'NA'
											) : items?.manual_result !== null ? (
												<Link to={redirectToManulAnnotation(items)}>
													{camelCase(
														JSON.stringify(items?.manual_result)
													)}
												</Link>
											) : (
												''
											)}
										</div>
										{/* Reference: Result */}
									</div>
								</td>
							)}
						</Fragment>
					</tr>
				))}
			</Fragment>
		);
		return body;
	};

	const onDropdownItem = (index) => {
		if (commonHeader[index].checked) {
			commonHeader[index].checked = false;
		} else {
			commonHeader[index].checked = true;
		}
		setCommonHeader([...commonHeader]);
		setObjectToLocal('studyValidityHeaderSelection', commonHeader);
	};

	const onToggle = (e, event, meta) => {
		if (meta.source === 'rootClose') {
			setDropDownShow(e);
		}
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					studyId={props.match.params.studyId}
					{...props}
				/>
			</ErrorBoundary>
			<div>
				<h3 className="v-cohort-name cohort-name-item">
					{subTitle}: {studyDetail.study_name}
					<Dropdown
						show={dropDownShow}
						drop="start"
						align="start"
						alignRight
						onToggle={onToggle}
					>
						<Dropdown.Toggle
							variant="success"
							id="study-validity-dropdown"
							onClick={() => {
								setDropDownShow(!dropDownShow);
							}}
						></Dropdown.Toggle>

						<Dropdown.Menu show={dropDownShow} autoClose="false">
							{commonHeader.map(function (item, i) {
								return (
									<Dropdown.Item className="study-validity-dropdown-item">
										<td key={i} onClick={() => onDropdownItem(i)}>
											<div className="study-validity-wrapper-checkbox">
												{item.checked ? (
													<i class="fas fa-check-square study-validity-checkbox"></i>
												) : (
													<i class="far fa-square study-validity-checkbox"></i>
												)}
												<span className="study-validity-text">
													{item.name}
												</span>
											</div>
										</td>
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</h3>
			</div>
			{loading && (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			)}
			<div className="study-validity-details-tbl-container">
				<table className="tbl-strip">
					<thead className="tbl-head">
						<TableHeader />
					</thead>
					<tbody>
						<TableBody />
					</tbody>
				</table>
			</div>
			<Copyright />
		</div>
	);
};

StudyValidityDrilldownDetails.propTypes = {
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			conceptId: PropTypes.any,
			studyId: PropTypes.any,
		}),
	}),
	study: PropTypes.shape({
		studyDetail: PropTypes.shape({
			study_name: PropTypes.any,
		}),
	}),
};

function mapStateToProps(state) {
	return { study: state.redStudy, loading: state.loading };
}

export default connect(mapStateToProps)(StudyValidityDrilldownDetails);
