import React, { useEffect, lazy, Suspense } from 'react';
import store from '../../store';
import constants from '../../constants';
import { connect } from 'react-redux';
import { setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import queryString from 'query-string';
import actions from '../../actions';
import Loading from 'react-loading-spinner';
import ErrorBoundary from '../../components/ErrorBoundary';
import {
	setTotalPatients,
	getStudyDetail,
	searchConceptsById,
	getEvent,
	getCohortList,
	getPatientDocuments,
	getDocEntityCounts,
	getDocumentIconsInfo,
	getEntityCounts,
	getEntityCountsAnnotator,
	getEncounterMulticlassUserEntityCounts,
	getPatientMulticlassUserEntityCounts,
	getEncounterTwoclassUserEntityCounts,
} from './PatientListHelper';
import { setIsLoading } from '../../actions/common';
import PropTypes from 'prop-types';
import { roleAccess } from '../../utilities/AppConstants';
const Patients = lazy(() =>
	import(/* webpackChunkName: "Patients" */ '../../components/Patients/Patients')
);
import patientIcon from '../../images/patient_icon.png';

const headerData = {
	title: 'Patient',
	searchTitle: 'patients',
	list: 'list',
	img: (
		<img
			src={patientIcon}
			style={{ marginRight: '15px', width: '12px' }}
			className={'App-PatientListImage'}
			alt={'Patients List'}
		/>
	),
	lastColumn: 'Data',
};

function PatientList(props) {
	let { userRole } = props.redSidenav;
	let { loading } = props.loading;
	const studyId = props?.match?.params?.studyId;
	const conceptId = props?.match?.params?.conceptId;
	const twoClass = props?.match?.params?.twoClass;
	const is_event = props?.match?.params?.is_event || 'false';
	const att_name = props?.match?.params?.att_name || '';
	const att_value = props?.match?.params?.att_value || '';
	const isConcept = props?.match?.params?.isConcept;
	const cohortCriteria = props?.match?.params?.cohortCriteria;

	useEffect(() => {
		setTotalPatients(0);
		let cohort_id = queryString.parse(window.location.search).cohort_id;
		let concept_id = queryString.parse(window.location.search).concept_id;
		let event_id = queryString.parse(window.location.search).event_id;
		let study_id = queryString.parse(window.location.search).study_id;
		let forResult = queryString.parse(window.location.search).forResult;

		if (study_id) {
			setIsLoading(true);
			let studyName = '';
			getStudyDetail(study_id).then((studyDetail) => {
				if (studyDetail) {
					studyName = studyDetail.study_name;
					if (concept_id) {
						searchConceptsById(concept_id).then((resp) => {
							let options = [
								{
									filterLabel: 'Study',
									name: studyName,
								},
								{
									filterLabel: forResult == 'true' ? 'Concept result' : 'Concept',
									name: resp[0].text,
								},
							];
							setIsLoading(false);
							setFilter(options);
						});
					} else if (event_id && event_id != 0) {
						getEvent().then((res) => {
							let events = res;
							let event = events.filter((item) => item.event_id == event_id);

							let eventName = event.length > 0 ? event[0].name : '';
							let options = [
								{
									filterLabel: 'Study',
									name: studyName,
								},
								{
									filterLabel: 'Event',
									name: eventName,
								},
							];
							setIsLoading(false);
							setFilter(options);
						});
					} else {
						let options = [
							{
								filterLabel: 'Study',
								name: studyName,
							},
						];
						setIsLoading(false);
						setFilter(options);
					}
				}
			});
		}
		if (concept_id && !study_id) {
			setIsLoading(true);
			searchConceptsById(concept_id).then((resp) => {
				setIsLoading(true);
				let options = [
					{
						filterLabel: forResult == 'true' ? 'Concept result' : 'Concept',
						name: resp[0].text,
					},
				];
				setFilter(options);
			});
		}

		if (cohort_id) {
			setIsLoading(true);
			getCohortList().then((res) => {
				setIsLoading(false);
				if (res.data.data) {
					let temp = res.data.data.filter((item) => item.cohort_id == cohort_id)[0];

					let options = [
						{
							filterLabel: 'Cohort',
							name: temp?.cohort_name,
						},
					];
					setFilter(options);
				}
			});
		}
	}, []);

	const setFilter = (options) => {
		let filterLabel = {
			page: 'patient',
			options: options,
		};
		setFilterLabel(filterLabel);
	};

	const fetchData = (patientId, organizationId, searchText, role) => {
		return new Promise((resolve) => {
			const initialCounts = {
				study_count: '',
				cohort_count: '',
				data_count: '',
				patient_count: '',
			};
			getPatientDocuments(patientId, organizationId, searchText, role)
				.then((docs) => {
					docs.map((doc) => {
						doc.counts = initialCounts;
					});
					if (role == roleAccess.ANNOTATOR) {
						getDocumentIconsInfo(docs, role)
							.then((documentWithIcons) => {
								const response = {
									document: documentWithIcons,
									docs: docs,
								};
								resolve(response);
							})
							.catch((error) => console.log(error));
					} else {
						getDocEntityCounts(docs)
							.then((docsWithCount) => {
								const response = {
									document: docsWithCount,
									docs: docs,
								};
								resolve(response);
							})
							.catch((error) => console.log(error));
					}
				})
				.catch((error) => console.log(error));
		});
	};

	const fetchEntitiesCount = () => {
		return new Promise((resolve) => {
			if (conceptId) {
				if (twoClass === 'encounter') {
					getEncounterMulticlassUserEntityCounts(studyId, conceptId).then((res) => {
						if (res && Object.keys(res).length) {
							resolve(res);
						}
					});
				}
				if (twoClass === 'patient') {
					const _cohortCriteria = isConcept === 'true' ? cohortCriteria : 'All';
					getPatientMulticlassUserEntityCounts(studyId, conceptId, _cohortCriteria).then(
						(res) => {
							if (res && Object.keys(res).length) {
								resolve(res);
							}
						}
					);
				}
				if (twoClass === 'twoClass') {
					getEncounterTwoclassUserEntityCounts(
						studyId,
						conceptId,
						is_event,
						att_name,
						att_value,
						isConcept
					).then((res) => {
						if (res && Object.keys(res).length) {
							resolve(res);
						}
					});
				}
			} else if (userRole.Role == roleAccess.ANNOTATOR) {
				getEntityCountsAnnotator().then((res) => {
					if (res && Object.keys(res).length) {
						resolve(res);
					}
				});
			} else {
				getEntityCounts().then((res) => {
					if (res && Object.keys(res).length) {
						resolve(res);
					}
				});
			}
		});
	};
	return (
		<React.Fragment>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<Patients
						filter={props.redSidenav.filter}
						headerData={headerData}
						isDataPage={0}
						userRole={userRole}
						fetchData={fetchData}
						fetchEntitiesCount={fetchEntitiesCount}
						{...props}
					/>
				</Suspense>
			</ErrorBoundary>
		</React.Fragment>
	);
}

PatientList.propTypes = {
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			att_name: PropTypes.string,
			att_value: PropTypes.string,
			cohortCriteria: PropTypes.any,
			conceptId: PropTypes.any,
			isConcept: PropTypes.string,
			is_event: PropTypes.string,
			studyId: PropTypes.any,
			twoClass: PropTypes.string,
		}),
	}),
	redSidenav: PropTypes.shape({
		filter: PropTypes.any,
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
};

const mapStateToProps = (state) => {
	return {
		redSidenav: state.redSidenav,
		loading: state.loading,
	};
};
export default connect(mapStateToProps)(PatientList);
