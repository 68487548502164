import React, { useState, useEffect } from 'react';
import actions from '../../../actions';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
function AccountPermissions(props) {
	const [permissions, setPermissions] = useState([]);

	useEffect(() => {
		getUserRole();
	}, []);

	//Description: This function is used to display a particular user access data from the backend who has login.
	const getUserRole = () => {
		props.dispatch(actions.getSystemUserDetails()).then((res) => {
			props.dispatch(actions.getUserPermissions(res.data.user_id)).then((res) => {
				setPermissions(res.data.data);
			});
		});
	};

	return (
		<div className="tab_modal_table" style={{ marginTop: 14 }}>
			<Table hover className="modal-data-table">
				<thead className="itemTitle">
					<tr>
						<th>Organization</th>
						<th>Role</th>
					</tr>
				</thead>
				<tbody>
					{permissions.map((permission, index) => (
						<tr key={index}>
							<td>
								{permission.organization_type}: {permission.organization_name}
							</td>
							<td>{permission.access}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
}

AccountPermissions.propTypes = {
	dispatch: PropTypes.func,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AccountPermissions);
