import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import Loading from 'react-loading-spinner';
import { useSelector } from 'react-redux';
import {
	setQueryParams,
	convertToPlural,
	rearrangeConceptCategories,
	setObjectToLocal,
	removeItemFromLocal,
} from '../../../utilities/utils';
import Copyright from '../../../components/Copyright/Copyright';
import { setIsLoading } from '../../../actions/common';
import {
	getSubdocumentById,
	getConceptCategories,
	getUserManualAnnotations,
	searchConceptsById,
} from './DataManualHelper';
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DataManual = (props) => {
	const [doc_name, setDoc_name] = useState('');
	const [content_type, setContent_type] = useState('');
	const [distinctCategory, setDistinctCategory] = useState('');
	const [manualAnnotationList, setManualAnnotationList] = useState([]);
	const [ifLoading, setIfLoading] = useState(false);
	let { docId } = props.match.params;
	const loading = useSelector((state) => state.loading)?.loading;
	const sidenav = useSelector((state) => state.redSidenav);
	const historyreducer = useSelector((state) => state.redDocument);

	useEffect(() => {
		setIsLoading(true);
		removeItemFromLocal('editmodeDocument');
		removeItemFromLocal('fromEditManual');
		removeItemFromLocal('fromDataManual');
		removeItemFromLocal('selectedConcept');
		removeItemFromLocal('editmodeNormal');
		removeItemFromLocal('newRapidAnnotation');
		removeItemFromLocal('updatedAnnotation');
		removeItemFromLocal('viewmodeDocument');
		removeItemFromLocal('deletedAnnotation');

		getSubdocumentById(docId).then((resp) => {
			setDoc_name(resp.data.data.name_with_date);
			setContent_type(resp.data.data.content_type);
		});

		//Fetch the concept categories from data store if present else fire an API to fetch it
		if (historyreducer.conceptCategoryList.length > 0) {
			let categories = rearrangeConceptCategories(historyreducer.conceptCategoryList);
			setDistinctCategory(categories);
		} else {
			getConceptCategories().then((resp) => {
				let categories = rearrangeConceptCategories(resp);
				setDistinctCategory(categories);
			});
		}
	}, []);

	useEffect(() => {
		if (sidenav.userRole.Role) {
			let m_annotationList = [];
			setIfLoading(true);

			getUserManualAnnotations(docId).then((annotation) => {
				let conceptIds = [];
				annotation.map((annotationList) => {
					conceptIds.push(annotationList.concept_id);
				});
				searchConceptsById(conceptIds)
					.then((criteriaConcepts) => {
						annotation.map((annotation) => {
							let concept = criteriaConcepts.filter(
								(item) => annotation.concept_id == item.cuid
							)[0];
							m_annotationList.push({ ...annotation, data: concept });
						});

						//Sort the list of manual annotations in the ascending order of the concept name
						m_annotationList = m_annotationList.sort(function (a, b) {
							if (a.data?.text.toLowerCase() < b.data?.text.toLowerCase()) return -1;
							if (a.data?.text.toLowerCase() > b.data?.text.toLowerCase()) return 1;
							return 0;
						});
						setManualAnnotationList(m_annotationList);
						setIfLoading(false);
						setIsLoading(false);
					})
					.finally(() => {
						setIsLoading(false);
						setIfLoading(false);
					});
			});
		}
	}, [sidenav.userRole]);

	const gotoDatadetail = () => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/document/${props.match.params.docId}`);
	};

	const gotoannotation = (items) => {
		if (items.is_present) {
			setQueryParams({
				pathname: `${constants.ROUTE_URL}/dashboard/document/${props.match.params.docId}`,
				query: {
					route_annotation_id: items.annotation_id,
				},
			});
		} else {
			setObjectToLocal('viewmodeDocument', true);
			setObjectToLocal('selectedConcept', items);
			setObjectToLocal('fromDataManual', true);
			props.history.push(
				`${constants.ROUTE_URL}/dashboard/document/${props.match.params.docId}`
			);
		}
	};

	return (
		<div className="DataAutomated">
			{(loading || ifLoading) && (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			)}
			<HeaderTitles
				data={headerData}
				search={false}
				history={props.history}
				match={props.match}
			/>
			<div className="document-header-container">
				<span>Manual annotations: </span>
				<span id="goto-Datadetail" className="hyperlink" onClick={gotoDatadetail}>
					{doc_name}
				</span>
			</div>

			{distinctCategory &&
				distinctCategory.map((category, categoryIndex) => {
					return (
						<React.Fragment key={category + categoryIndex}>
							<div className="section-header">
								{category ? convertToPlural(category) : 'Others'}
							</div>
							<ul>
								{manualAnnotationList &&
									manualAnnotationList
										.filter((annotation) => {
											return annotation.concept_category == category;
										})
										.map((items, index) => {
											return (
												<li key={category + categoryIndex + index}>
													<div className="list-item">
														<span
															className="hyperlink"
															onClick={() => gotoannotation(items)}
														>
															{items.is_present &&
															!items.is_experienced
																? 'No '
																: ''}
															{items.data?.text}
														</span>{' '}
														({items?.data?.sab} {items?.data?.code})
														{!items.is_present ? ' not mentioned' : ''}
													</div>
												</li>
											);
										})}
							</ul>
						</React.Fragment>
					);
				})}
			<Copyright />
		</div>
	);
};

DataManual.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
		}),
	}),
};

export default DataManual;
