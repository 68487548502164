import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import constants from '../../../constants';
import actions from '../../../actions';
import Loading from 'react-loading-spinner';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Copyright from '../../../components/Copyright/Copyright';
import SortComponent from '../../../common/SortComponent/SortComponent';
import {
	setIsLoading,
	updateStudyValidation,
	setSideNavList,
	getStudyDetail,
	getEncounterTwoClassConceptDetails,
} from './StudyValidityDrilldownHelper';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../../common/Pagination/PaginationComponent';
import trialIcon from '../../../images/trial_icon.png';

const headerData = {
	title: 'Validity',
	list: 'detail',
	img: <img src={trialIcon} className={'App-TrialList'} alt={'Trial List'} />,
};

let totalRecords = 10;

const StudyValidityDrilldown = (props) => {
	const {
		studyId,
		concept_id,
		arm_type,
		att_name,
		att_value,
		is_event,
		concept_name,
		disabled_drilldown,
	} = props.match.params;
	let criteria = actions.getStudyValidationCriteria(studyId);
	let subTitle = (criteria.column && criteria.column.label) || 'Accuracy';
	const { studyDetail } = props.study;
	let { loading } = props.loading;
	const [drilldownData, setDrilldownData] = useState([]);
	let [columnName, setColumnName] = useState('narrative_result');
	let [sortOrder, setSortOrder] = useState('asc');
	const [pageNo, setPageNo] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(10);
	const [type, setType] = useState('');

	useEffect(() => {
		updateStudyValidation([]);
		setSideNavList([]);
		if (studyId) {
			getStudyDetail(studyId).then((studyDetail) => {
				let label = 'Concept';
				let filterLabel = {
					page: 'studyvalidity',
					options: [
						{
							filterLabel: 'Study',
							name: studyDetail.study_name,
							fixed: true,
						},
						{
							filterLabel: label,
							name: concept_name?.split('-').join(' '),
						},
					],
				};
				setFilterLabel(filterLabel);
			});
		}
		setTableData();
	}, []);

	useEffect(() => {
		setTableData();
	}, [pageNo, recordsPerPage]);

	useEffect(() => {
		const _drilldownData = Object.assign([], drilldownData);

		if (sortOrder === 'asc') {
			_drilldownData.sort((a, b) => {
				let fa = a[type],
					fb = b[type];

				if (fa < fb) {
					return -1;
				}
			});
		} else {
			_drilldownData.sort((a, b) => {
				let fa = a[type],
					fb = b[type];

				if (fa > fb) {
					return -1;
				}
			});
		}

		setDrilldownData(_drilldownData);
	}, [sortOrder]);

	const setTableData = () => {
		let m_descriptor = '';
		let m_severity = '';
		let m_progression = '';

		if (att_name === 'Descriptor') {
			m_descriptor = att_value;
		}
		if (att_name === 'Severity') {
			m_severity = att_value;
		}
		if (att_name === 'Progression') {
			m_progression = att_value;
		}
		setIsLoading(true);

		getEncounterTwoClassConceptDetails(
			concept_id,
			arm_type,
			m_descriptor,
			m_severity,
			m_progression,
			is_event,
			pageNo,
			recordsPerPage
		)
			.then((res) => {
				if (pageNo === 1) {
					totalRecords = res?.totalRecords;
				}
				setDrilldownData(formatData(res?.data) || []);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
			});
	};

	const formatData = (data) => {
		const sortedData = data.sort((a, b) => {
			return b?.referenceStandardAnnotation - a?.referenceStandardAnnotation;
		});
		return sortedData;
	};

	const sortASC = (_columnName, _order, _type) => {
		setSortOrder(_order);
		setColumnName(_columnName);
		setType(_type);
	};

	const camelCase = (str) => {
		if (isNaN(str) && str) {
			const str2 = str.toLowerCase();
			return str2.charAt(0).toUpperCase() + str2.slice(1);
		}
		return str;
	};

	const DrilldownHeader = () => {
		return (
			<tr>
				<td>
					<div className="first" onClick={() => sortASC('patient_name')}>
						<span>Patients</span>
						<SortComponent
							columnName={'patient_name'}
							sortedColumnName={columnName}
							sortOrder={sortOrder}
							sortASC={(a, b) => sortASC(a, b, 'patient_identifier')}
						/>
						{/* patient_identifier */}
					</div>
				</td>
				<td>
					<div className="text-left" onClick={() => sortASC('Encounters')}>
						<span>Encounters</span>
						<SortComponent
							columnName={'Encounters'}
							sortedColumnName={columnName}
							sortOrder={sortOrder}
							sortASC={(a, b) => sortASC(a, b, 'encounter_name')}
						/>
						{/* encounter_name */}
					</div>
				</td>
				{arm_type === 'Structured' && (
					<td>
						<div onClick={() => sortASC('discrete_result')}>
							<span>Structured annotation </span>
							<SortComponent
								columnName={'discrete_result'}
								sortedColumnName={columnName}
								sortOrder={sortOrder}
								sortASC={(a, b) => sortASC(a, b, 'structuredAnnotation')}
							/>
						</div>
						{/* structuredAnnotation */}
					</td>
				)}
				{arm_type === 'Unstructured' && (
					<td>
						<div onClick={() => sortASC('discrete_result')}>
							<span>NLP annotation </span>
							<SortComponent
								columnName={'discrete_result'}
								sortedColumnName={columnName}
								sortOrder={sortOrder}
								sortASC={(a, b) => sortASC(a, b, 'unstructuredAnnotation')}
							/>
							{/* unstructuredAnnotation */}
						</div>
					</td>
				)}
				{arm_type === 'Inferred' && (
					<td>
						<div onClick={() => sortASC('discrete_result')}>
							<span>Inferred annotation </span>
							<SortComponent
								columnName={'discrete_result'}
								sortedColumnName={columnName}
								sortOrder={sortOrder}
								sortASC={(a, b) => sortASC(a, b, 'unstructuredAnnotation')}
							/>
							{/* unstructuredAnnotation */}
						</div>
					</td>
				)}
				<td>
					<div onClick={() => sortASC('narrative_result')}>
						<span>Reference standard annotation </span>
						<SortComponent
							columnName={'narrative_result'}
							sortedColumnName={columnName}
							sortOrder={sortOrder}
							sortASC={(a, b) => sortASC(a, b, 'referenceStandardAnnotation')}
						/>
						{/* referenceStandardAnnotation */}
					</div>
				</td>
				{arm_type === 'Structured' && (
					<td>
						<div
							onClick={() => sortASC('manual_annotation_result')}
							className="study-validity-item-left"
						>
							<span>Structured classification</span>
							<SortComponent
								columnName={'manual_annotation_result'}
								sortedColumnName={columnName}
								sortOrder={sortOrder}
								sortASC={(a, b) => sortASC(a, b, 'classification')}
							/>
							{/* classification */}
						</div>
					</td>
				)}
				{arm_type === 'Unstructured' && (
					<td>
						<div
							onClick={() => sortASC('manual_annotation_result')}
							className="study-validity-item-left"
						>
							<span>NLP classification</span>
							<SortComponent
								columnName={'manual_annotation_result'}
								sortedColumnName={columnName}
								sortOrder={sortOrder}
								sortASC={(a, b) => sortASC(a, b, 'classification')}
							/>
							{/* classification */}
						</div>
					</td>
				)}
				{arm_type === 'Inferred' && (
					<td>
						<div
							onClick={() => sortASC('manual_annotation_result')}
							className="study-validity-item-left"
						>
							<span>Inferred classification</span>
							<SortComponent
								columnName={'manual_annotation_result'}
								sortedColumnName={columnName}
								sortOrder={sortOrder}
								sortASC={(a, b) => sortASC(a, b, 'classification')}
							/>
							{/* classification */}
						</div>
					</td>
				)}
			</tr>
		);
	};

	const getNavLink = (data, datatype) => {
		const path =
			`${constants.ROUTE_URL 
			}/dashboard/twoclassdrilldowndetails/${ 
			studyId 
			}/${ 
			concept_id 
			}/${ 
			data.encounterId 
			}/${ 
			att_name 
			}/${ 
			att_value 
			}/${ 
			datatype 
			}/${ 
			is_event 
			}/${ 
			concept_name}`;

		return path;
	};

	const referenceStandardAnnotationView = (items) => {
		if (arm_type === 'Unstructured') {
			if (disabled_drilldown == 'true') {
				return <div>{camelCase(JSON.stringify(items.referenceStandardAnnotation))}</div>;
			}
		}
		return (
			<Link className="hyperlink" to={getNavLink(items, arm_type)}>
				{camelCase(JSON.stringify(items.referenceStandardAnnotation))}
			</Link>
		);
	};

	const renderEncounter = (items) => {
		if (items.encounterId) {
			return (
				<div className="text-left">
					<Link
						className="hyperlink"
						to={`${constants.ROUTE_URL  }/dashboard/document/${  items.encounterId}`}
					>
						{items.encounter_name || items.encounterId}
					</Link>
				</div>
			);
		} else {
			return (
				<div className="text-left">
					<div>{items.encounter_name || items.encounterId}</div>
				</div>
			);
		}
	};

	const DrilldownBody = () => {
		return (
			<Fragment>
				{drilldownData.map((items, index) => {
					return (
						<tr key={index}>
							<td>
								<div className="first">
									<Link
										className="hyperlink"
										to={
											`${constants.ROUTE_URL 
											}/dashboard/patient/${ 
											items.patientId}`
										}
										id="testid-link-4"
									>
										{items.patient_identifier || items.patientId}
									</Link>
								</div>
							</td>
							<td>{renderEncounter(items)}</td>
							{arm_type === 'Structured' && (
								<td>
									<div>
										<Link
											className="hyperlink"
											to={getNavLink(items, 'Structured')}
										>
											{camelCase(JSON.stringify(items.structuredAnnotation))}
										</Link>
									</div>
								</td>
							)}
							{arm_type === 'Unstructured' && (
								<td>
									<div>
										{disabled_drilldown == 'false' ? (
											<Link
												className="hyperlink"
												to={getNavLink(items, 'Unstructured')}
											>
												{camelCase(
													JSON.stringify(items.unstructuredAnnotation)
												)}
											</Link>
										) : (
											<div>
												{camelCase(
													JSON.stringify(items.unstructuredAnnotation)
												)}
											</div>
										)}
									</div>
								</td>
							)}
							{arm_type === 'Inferred' && (
								<td>
									<div>
										<Link
											className="hyperlink"
											to={getNavLink(items, 'Inferred')}
										>
											{camelCase(
												JSON.stringify(items.unstructuredAnnotation)
											)}
										</Link>
									</div>
								</td>
							)}
							<td>{referenceStandardAnnotationView(items)}</td>
							<td>
								<div className="study-validity-item-left">
									{camelCase(items.classification)}
								</div>
							</td>
						</tr>
					);
				})}
			</Fragment>
		);
	};

	const gotoPage = (nextPageNo, nextrecordsPerPage = 10) => {
		setPageNo(nextPageNo);
		setRecordsPerPage(nextrecordsPerPage);
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					studyId={props.match.params.studyId}
					{...props}
				/>
			</ErrorBoundary>
			<div>
				<h3 className="v-cohort-name">
					{subTitle}: {studyDetail.study_name}
				</h3>
			</div>
			{loading && (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			)}
			<div className="study-validity-drilldown-tbl-container">
				<table className="tbl-strip">
					<thead className="tbl-head">
						<DrilldownHeader />
					</thead>
					<tbody>
						<DrilldownBody />
					</tbody>
				</table>
			</div>
			<div>
				{drilldownData[0] && (
					<PaginationComponent
						totalItemsCount={totalRecords}
						gotoPage={(pageNo, recordsPerPage) => gotoPage(pageNo, recordsPerPage)}
						currentPage={pageNo}
					/>
				)}
			</div>
			<Copyright />
		</div>
	);
};

StudyValidityDrilldown.propTypes = {
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			conceptId: PropTypes.any,
			studyId: PropTypes.any,
		}),
	}),
	study: PropTypes.shape({
		studyDetail: PropTypes.shape({
			study_name: PropTypes.any,
		}),
	}),
};

function mapStateToProps(state) {
	return { study: state.redStudy, loading: state.loading };
}

export default connect(mapStateToProps)(StudyValidityDrilldown);
