import React, {
	useState,
	useEffect,
	createRef,
	forwardRef,
	useImperativeHandle,
	lazy,
	Suspense,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import { Tabs, Tab } from 'react-bootstrap';
import constants from '../../constants';
import { withRouter } from 'react-router-dom';
import SearchComponent from '../../common/SearchComponent/SearchComponent';
import HamburgerMenu from '../../common/Header/HamburgerMenu';
import { filterList, setQueryParams } from '../../utilities/utils';
import ErrorBoundary from '../../components/ErrorBoundary';
import {
	getUserBatches,
	getUserProject,
	openModal,
	getCohortList,
	getUserDetails,
} from './DataManagementHelper';
import removeMinusIcon from '../../images/remove_minus_icon.svg';
import addPlusIcon from '../../images/add_plus_icon.svg';

const UploadData = lazy(() =>
	import(/* webpackChunkName: "UploadData" */ '../../components/UploadData/UploadData')
);

const ProjectUpload = lazy(() =>
	import(/* webpackChunkName: "ProjectUpload" */ '../../components/ProjectUpload/ProjectUpload')
);
const ManualUploadText = lazy(() =>
	import(
		/* webpackChunkName: "ManualUploadText" */ '../../components/ManualUploadText/ManualUploadText'
	)
);

const menuOptionsArr = [
	{ eventKey: 1, label: 'Add batch' },
	{ eventKey: 2, label: 'Delete batch' },
	{ eventKey: 3, label: 'Search batches' },
	{ eventKey: 5, label: 'Manual upload' },
];

const menuOptionsProject = [{ eventKey: 4, label: 'Edit manual annotation projects' }];

const DataManagement = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false || props.openModal);
	const [key, setKey] = useState(1);
	const [showUploadForm, setShowUploadForm] = useState(false);
	const [showProjectForm, setShowProjectForm] = useState(false);
	const [showManualUpload, setShowManualUpload] = useState(false);
	const [batches, setBatches] = useState([]);
	const [showSearch, setShowSearch] = useState(false);
	const [searchBatchText, setSearchBatchText] = useState('');
	const [deleteMode, setDeleteMode] = useState(false);
	const [menuOptions, setMenuOptions] = useState(menuOptionsArr);
	const [showForm, setShowForm] = useState(false);
	const [projects, setProjects] = useState([]);
	const [users, setUsers] = useState([]);
	const previewPopup = createRef();

	useImperativeHandle(ref, () => {
		handleClick: handleClick;
	});

	useEffect(() => {
		props.open({
			handleClick: () => handleClick(),
		});
		let path = window.location.pathname;
		if (
			sessionStorage.getItem('PreviewText') &&
			path.indexOf('datapreview') == -1 &&
			path.indexOf('conceptsupport') == -1 &&
			path.indexOf('patient/0') == -1
		) {
			setOpen(true);
			setKey(5);
			setShowManualUpload(true);
			setShowForm(true);
			setShowProjectForm(false);
			setShowUploadForm(false);
		} else {
			setShowManualUpload(false);
			sessionStorage.getItem('PreviewText');
			setShowForm(false);
			setShowProjectForm(false);
			setShowUploadForm(false);
		}
		return () => {
			props.open(null);
		};
	}, []);

	useEffect(() => {
		if (showProjectForm) {
			getCohortList();
			getUserDetails().then((res) => {
				setUsers(res?.data?.data || []);
			});
		}
	}, [showProjectForm]);

	const getBatchData = () => {
		getUserBatches().then((res) => {
			setBatches(res);
		});
	};

	const getProjectData = (pageNumber) => {
		getUserProject().then((res) => {
			setProjects(res);
		});
	};

	const toggleUploadForm = () => {
		setShowUploadForm(!showUploadForm);
		setShowForm(!showForm);
	};

	const toggleProjectForm = () => {
		setShowForm(!showForm);
		setShowProjectForm(!showProjectForm);
	};

	/*****************************************
	 * Function name: handleClick
	 * Description: This function ters the state of the modal.
	//  * Sets the open state of the modal to true to open the modal.
	****************************************/
	const handleClick = () => {
		let path = window.location.pathname;

		if (
			sessionStorage.getItem('PreviewText') &&
			path.indexOf('datapreview') == -1 &&
			path.indexOf('conceptsupport') == -1
		) {
			setOpen(true);
			setKey(5);
			setShowManualUpload(true);
			setShowForm(true);
			setShowProjectForm(false);
			setShowUploadForm(false);
		} else {
			setOpen(true);
		}
		getBatchData();
		getProjectData();
	};

	/*****************************************
	 * Function name: handleClose
	 * Description: This function alters the state of the modal.
	 * Sets the open state of the modal to false to close the modal.
	 * Also adds a css class to it for designing.
	 ****************************************/
	const handleClose = (key) => {
		if (key) {
			setOpen(false);
			setKey(5);
		} else {
			setOpen(false);
			setKey(1);
			setShowManualUpload(false);
			setShowForm(false);
			setShowProjectForm(false);
			setShowUploadForm(false);
		}
		//SET CLOSE MODAL IN REDUX
		openModal('');
	};

	const gotoDataListPage = (batchId) => {
		//CLOSE MODAL POPUP
		handleClose();

		setQueryParams({
			pathname: `${constants.ROUTE_URL}/dashboard/datalist`,
			query: {
				m_batchId: batchId,
				filter: true,
			},
		});
	};

	const handleOptionSelect = (option) => {
		setShowForm(false);
		if (option == 1) {
			toggleUploadForm();
		} else if (option == 2) {
			setDeleteMode(!deleteMode);
		} else if (option == 3) {
			setShowSearch(true);
		} else if (option == 4) {
			setDeleteMode(!deleteMode);
		} else if (option == 5) {
			setShowManualUpload(true);
			setShowForm(true);
			setShowProjectForm(false);
			setShowUploadForm(false);
		}
	};

	const searchBatch = (event) => {
		setSearchBatchText(event.target.value);
	};

	const handleSelect = (keyOption) => {
		if (keyOption == 1) {
			setMenuOptions(menuOptionsArr);
		}
		if (keyOption == 3) {
			setMenuOptions(menuOptionsProject);
		}
		setKey(keyOption);
	};

	const deleteProjects = (project_id) => {};
	const closeManualUpload = () => {
		setKey(1);
		setShowManualUpload(false);
		setShowForm(false);
		setShowProjectForm(false);
		setShowUploadForm(false);
	};

	const filteredBatches = filterList(searchBatchText, batches, [{ dataKey: 'batch_name' }]);
	return (
		<div>
			<ErrorBoundary>
				<ModalComponent
					open={open}
					handleClose={handleClose}
					content={
						<div className="modal_body_content">
							{!showForm ? (
								<React.Fragment>
									<div className="modal-heading headerbar product-setting">
										<div
											style={{
												display: 'inline-block',
												width: '50%',
												paddingTop: 4,
												paddingLeft: 0,
												margin: 0,
											}}
										>
											Data administration
										</div>
										<div
											style={{ display: 'inline-block', width: '15%' }}
										></div>
										<div style={{ display: 'inline-block', width: '30%' }}>
											{showSearch && (
												<div
													className="searchContainer"
													style={{ width: '100%' }}
												>
													<SearchComponent
														title="batches"
														searchFunction={searchBatch}
													/>
												</div>
											)}
										</div>

										<div
											style={{
												display: 'inline-block',
												width: '5%',
												textAlign: 'right',
											}}
										>
											<HamburgerMenu
												userRole={props.redSidenav.userRole}
												options={menuOptions}
												handleMenuClick={handleOptionSelect}
											/>
										</div>
									</div>
									<div className="modal-container">
										<Tabs
											activeKey={key}
											onSelect={handleSelect}
											id="controlled-tab-example"
											className="nav-tabs-update"
										>
											<Tab eventKey={1} title="Data upload">
												<TableBatch
													data={filteredBatches}
													gotoDataListPage={gotoDataListPage}
													deleteMode={deleteMode}
												/>
											</Tab>
											<Tab eventKey={2} title="Data download" disabled>
												Tab 2 content
											</Tab>
											<Tab eventKey={3} title="Manual annotation">
												<TableProjects
													data={projects}
													deleteMode={deleteMode}
													deleteMethod={deleteProjects}
													addProjectForm={toggleProjectForm}
												/>
											</Tab>
										</Tabs>
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									{showUploadForm && (
										<Suspense fallback={<div className="defaultSpinner" />}>
											<UploadData
												handleClose={toggleUploadForm}
												handleSelect={handleSelect}
											/>
										</Suspense>
									)}
									{showProjectForm && (
										<Suspense fallback={<div className="defaultSpinner" />}>
											<ProjectUpload
												handleClose={toggleProjectForm}
												deleteMode={deleteMode}
												handleSelect={handleSelect}
												users={users}
											/>
										</Suspense>
									)}
									{showManualUpload && (
										<Suspense fallback={<div className="defaultSpinner" />}>
											<ManualUploadText
												popupRef={previewPopup}
												handleClose={closeManualUpload}
												closeAll={handleClose}
											/>
										</Suspense>
									)}
								</React.Fragment>
							)}
							<div className="modal-inner-footer">
								<div className="offset-md-6 col-md-6 center">
									<button
										type="button"
										className="btn-cancel"
										onClick={handleClose}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					}
					className="Data-Management"
				/>
			</ErrorBoundary>
		</div>
	);
});

DataManagement.propTypes = {
	open: PropTypes.func,
	openModal: PropTypes.any,
	redSidenav: PropTypes.shape({
		userRole: PropTypes.any,
	}),
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(DataManagement));

const TableBatch = ({ data, gotoDataListPage, deleteMode }) => {
	return (
		<table className="tbl-tech-detail" style={{ marginTop: 20 }}>
			<thead>
				<tr>
					<th>Batch name</th>
					<th>Provider</th>
					<th>Hospital</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				{data.map((batch, index) => (
					<tr key={index}>
						<td>
							{deleteMode && (
								<span>
									<img
										src={removeMinusIcon}
										className="App-minusIcon"
										alt="minusIcon"
										width={20}
									/>
									<span style={{ padding: 4 }}></span>
								</span>
							)}
							<span
								className="hyperlink"
								onClick={() => gotoDataListPage(batch.batch_id)}
							>
								{batch.batch_name}
							</span>
						</td>
						<td>{batch.provider_name}</td>
						<td>{batch.organization_name}</td>
						<td>{batch.status}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

const TableProjects = ({ data, deleteMode, deleteMethod, addProjectForm }) => {
	return (
		<table className="tbl-tech-detail" style={{ marginTop: 20 }}>
			<thead>
				<tr>
					<th>Project name</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				{data.map((project, index) => (
					<tr key={index}>
						<td>
							{deleteMode && (
								<span>
									<img
										onClick={() => deleteMethod(project.projectId)}
										src={removeMinusIcon}
										className="App-minusIcon"
										alt="minusIcon"
										width={20}
									/>
									<span style={{ padding: 4 }}></span>
								</span>
							)}
							<span className="hyperlink">{project.projectName}</span>
						</td>
						<td>{project.status}</td>
					</tr>
				))}
				<tr>
					<td>
						{deleteMode && (
							<span>
								<img
									onClick={addProjectForm}
									src={addPlusIcon}
									className="App-minusIcon"
									alt="minusIcon"
									width={20}
								/>
								<span style={{ padding: 4 }}></span>
							</span>
						)}
					</td>
					<td></td>
				</tr>
			</tbody>
		</table>
	);
};
