/* istanbul ignore file */
import actionTypes from '../actions/actionTypes';

//defining initial state for the reducer
let initialState = {
	codeErr:'',
	error: '',
	success: false,
	step2verify: false,
	// loading: false
};

//reducer that matches the action type for Auth actions and changes the state
export const authReducer = (state=initialState, action) => {
	switch(action.type){

		// case actionTypes.AUTH_LOGIN : {
		// 	return {...state, loading: true}
		// }

		case actionTypes.AUTH_LOGIN_SUCCESS : {
			return {...state , error: "",success: action.payload, loading: false }
		}

		case actionTypes.AUTH_LOGIN_ERROR : {
			return {...state , error: "Wrong email or password.", loading: false, success: false }
		}

		case actionTypes.STEP2_VERIFICATION_SUCCESS : {
			return {...state , step2verify: action.payload }
		}

		// case actionTypes.VERIFY_AUTHENTICATION_CODE : {
		// 	return {...state , success: action.payload, loading: false }
		// }

		case actionTypes.VERIFY_AUTHENTICATION_ERROR : {
			return {...state , codeErr: "Invalid code. Please try again.", loading: false, success: false }
		}
		case actionTypes.RESET_STATE : {
			state = undefined;
			return null;
		}
		default: {
			return state;
		}
	}
}
