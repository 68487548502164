/* istanbul ignore file */
import { setObjectToLocal, getObjectFromLocal } from '../../../utilities/utils';

export const getSectionText = (sectionJson) => {
	return new Promise((resolve, reject) => {
		const sections = sectionJson;

		sections.map((section) => {
			let annotation = section.entities.sort(function (a, b) {
				if (a.BeginOffset < b.BeginOffset) return -1;
				if (a.BeginOffset > b.BeginOffset) return 1;
				return 0;
			});

			if (section.hasOwnProperty('section_text_deidentified')) {
				section.section_text = section.section_text_deidentified;
			}

			var response = section.section_text
				.replace(/\r\n/g, '\n')
				.replace(/\r/g, '\n')
				.replace(/\'/g, '`');
			var formattedText = '';

			let lastIndex = 0;
			annotation.map((obj, annotationIndex) => {
				for (; lastIndex < response.length; lastIndex++) {
					if (lastIndex === obj.BeginOffset) {
						// new code : to find actual text

						let actualTextFromSection = '';
						for (var i = obj.BeginOffset; i <= obj.EndOffset; i++) {
							actualTextFromSection += response[i];
						}

						// new code : to check ConceptCodes in the json as annotation

						//    let sameTextAnnotation = annotation.filter(
						//     val => {
						//         val.Text = val.Text.replace(/\'/g, "`").replace(/\"/g, "`");
						//         val.ConceptName = val.ConceptName && val.ConceptName.replace(/\'/g, "`").replace(/\"/g, "`") || '';
						//         val.Technology = val.Technology && val.Technology.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;") || '';
						//         val.Category = val.Category.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;");
						//         if(val.dependency_modifier) {
						//             val.dependency_modifier =  val.dependency_modifier.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;");
						//         }
						//         if(val.root_word){
						//             val.root_word = val.root_word.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;");
						//         }
						//         return val.BeginOffset == obj.BeginOffset;
						//     });

						//     sameTextAnnotation[0].ConceptCodes && sameTextAnnotation[0].ConceptCodes.map(concept=>{
						//         concept.Term =  concept.Term.replace(/\'/g, "`").replace(/\"/g, "`");
						//     });
						let actualText = actualTextFromSection
							.replace(/\&#39;/g, "'")
							.replace(/\&#34;/g, '"');

						let updatedText = getActualText(obj.BeginOffset, actualText, section);
						updatedText = updatedText.replace(/\'/g, '&#39;').replace(/\"/g, '&#34;');
						formattedText +=
							"<a href='#' sid=" +
							section.section_id +
							" bost='" +
							lastIndex +
							"' eost='" +
							(parseInt(lastIndex) + parseInt(actualText.length - 1)) +
							"'  data-type='automated'>" +
							updatedText +
							'</a>';
					} else if (lastIndex > obj.BeginOffset && lastIndex <= obj.EndOffset) {
						formattedText += '';
					} else if (lastIndex <= obj.EndOffset) {
						let str = response.charAt(lastIndex).toString();
						formattedText +=
							'<span sid=' +
							section.section_id +
							" ost='" +
							lastIndex +
							"'>" +
							response.charAt(lastIndex) +
							'</span>';
					}
				}
				lastIndex = obj.EndOffset + 1;
			});

			for (lastIndex; lastIndex < response.length; lastIndex++) {
				formattedText +=
					'<span sid=' +
					section.section_id +
					" ost='" +
					lastIndex +
					"'>" +
					response.charAt(lastIndex) +
					'</span>';
			}

			section.sectionText = formattedText;
		});
		resolve(sections);
	});
};

export const getSectionTextPreview = (sectionJson) => {
	return new Promise((resolve, reject) => {
		const sections = sectionJson;

		sections.map((section) => {
			let annotation = section.entities.sort(function (a, b) {
				if (a.BeginOffset < b.BeginOffset) return -1;
				if (a.BeginOffset > b.BeginOffset) return 1;
				return 0;
			});

			if (section.hasOwnProperty('section_text_deidentified')) {
				section.section_text = section.section_text_deidentified;
			}

			var response = section.section_text
				.replace(/\r\n/g, '\n')
				.replace(/\r/g, '\n')
				.replace(/\'/g, '`');
			var formattedText = '';

			let lastIndex = 0;
			let breakPoint = 0;
			annotation.map((obj, annotationIndex) => {
				for (; lastIndex < response.length; lastIndex++) {
					if (lastIndex === obj.BeginOffset) {
						// new code : to find actual text

						let actualTextFromSection = '';
						let containLetter = false;
						for (var i = obj.BeginOffset; i <= obj.EndOffset; i++) {
							if (response[i] != '*') {
								if (response[i] != ' ') {
									containLetter = true;
								}
							}
							if (response[i] != ' ') {
								if (response[i] != '*') {
									containLetter = true;
								}
							}
							actualTextFromSection += response[i];
						}

						// new code : to check ConceptCodes in the json as annotation

						// let sameTextAnnotation = annotation.filter(
						//  val => {
						//      val.Text = val.Text.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;");
						//      val.ConceptName = val.ConceptName && val.ConceptName.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;") || '';
						//      val.Technology = val.Technology && val.Technology.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;") || '';
						//      val.Category = val.Category.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;");
						//      val.PreferredName = val.PreferredName && val.PreferredName.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;") || '';
						//      if(val.root_word){
						//         val.root_word = val.root_word.replace(/\'/g, "&#39;").replace(/\"/g, "&#34;");
						//     }
						//      return val.BeginOffset == obj.BeginOffset;
						//  });

						//  sameTextAnnotation[0].ConceptCodes && sameTextAnnotation[0].ConceptCodes.map(concept=>{
						//      concept.Term =  concept.Term.replace(/\'/g, "`").replace(/\"/g, "`");
						//  });
						let actualText = actualTextFromSection
							.replace(/\&#39;/g, "'")
							.replace(/\&#34;/g, '"');

						let updatedText = getActualText(obj.BeginOffset, actualText, section);
						updatedText = updatedText.replace(/\'/g, '&#39;').replace(/\"/g, '&#34;');
						if (containLetter) {
							formattedText +=
								"<a href='#' sid=" +
								section.section_id +
								" bost='" +
								lastIndex +
								"' eost='" +
								(parseInt(lastIndex) + parseInt(actualText.length - 1)) +
								"'  data-type='automated'>" +
								updatedText +
								'</a>';
						} else {
							formattedText += updatedText;
						}
					} else if (lastIndex > obj.BeginOffset && lastIndex <= obj.EndOffset) {
						formattedText += '';
					} else if (lastIndex <= obj.EndOffset) {
						let str = response.charAt(lastIndex).toString();

						formattedText +=
							'<span sid=' +
							section.section_id +
							" ost='" +
							lastIndex +
							"'>" +
							response.charAt(lastIndex) +
							'</span>';
					}
				}
				lastIndex = obj.EndOffset + 1;
			});

			for (lastIndex; lastIndex < response.length; lastIndex++) {
				formattedText +=
					'<span sid=' +
					section.section_id +
					" ost='" +
					lastIndex +
					"'>" +
					response.charAt(lastIndex) +
					'</span>';
			}

			section.sectionText = formattedText;
		});
		resolve(sections);
	});
};

export const applyManualAnnotation = (automatedText, textAnnotation) => {
	return new Promise((resolve, reject) => {
		let textAnnotations = [];
		textAnnotations = textAnnotation;
		let temp = getObjectFromLocal('newRapidAnnotation');
		let newRapidAnnotationTemp = temp ? temp : '';
		if (newRapidAnnotationTemp) {
			newRapidAnnotationTemp.map((items) => {
				textAnnotations.push(items);
			});
		}

		automatedText.map((sectionText, index) => {
			let currentSection = [];
			if (textAnnotations) {
				currentSection = textAnnotations.filter((item) => {
					return item.section_number == sectionText.section_id;
				});
			}

			let htmltext = $('<div>' + sectionText.sectionText + '</div>');

			if (currentSection.length > 0) {
				currentSection.map((item) => {
					let parent = htmltext.find('span[ost="' + item.end_offset + '"]').parent();
					let lastChild = false;
					if (parent.is('a')) {
						if (
							htmltext.find('span[ost="' + item.end_offset + '"]').is(':last-child')
						) {
							lastChild = true;
						}
					}
					if (lastChild) {
						htmltext
							.find('span[ost="' + item.end_offset + '"]')
							.parent()
							.after(
								'<span sid=' +
									sectionText.section_id +
									' data-startOffSet=' +
									item.begin_offset +
									' data-endOffSet=' +
									item.end_offset +
									" data-type='manualannotation' data-annotationid='" +
									item.annotation_id +
									"' data-uid='" +
									(item.unique_id || 0) +
									"'></span>"
							);
					} else {
						htmltext
							.find('span[ost="' + item.end_offset + '"]')
							.after(
								'<span sid=' +
									sectionText.section_id +
									' data-startOffSet=' +
									item.begin_offset +
									' data-endOffSet=' +
									item.end_offset +
									" data-type='manualannotation' data-annotationid='" +
									item.annotation_id +
									"' data-uid='" +
									(item.unique_id || 0) +
									"'></span>"
							);
					}
				});
			}
			automatedText[index].sectionText = htmltext[0].innerHTML;
		});
		resolve(automatedText);
		// });
	});
};

var getActualText = (startIndex, actualtext, section) => {
	let txt = '';
	[...actualtext].map((item, index) => {
		if (item == '\n') {
			txt += '<br />';
		} else {
			txt +=
				'<span sid=' +
				section.section_id +
				' ost=' +
				(startIndex + index) +
				'>' +
				item.toString() +
				'</span>';
		}
	});

	return txt;
};

export const addManualAnnotationLS = (annotation) => {
	let lsAnnotations = getObjectFromLocal('rapidannotaionlist');
	let arrAnnotaion;
	if (lsAnnotations) {
		arrAnnotaion = lsAnnotations;
		arrAnnotaion.push(annotation);
		setObjectToLocal('rapidannotaionlist', arrAnnotaion);
	} else {
		setObjectToLocal('rapidannotaionlist', [arrAnnotaion]);
	}
};

export const deleteManualAnnotationLS = () => {
	setObjectToLocal('rapidannotaionlist', []);
};

export const getAllManualAnnotationLS = () => {
	return getObjectFromLocal('rapidannotaionlist') || [];
};
