/* istanbul ignore file */
import { apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';

export const getEncounterTwoClassEncounterDetails = (
	conceptId,
	subdocumentId,
	datatype,
	isEvent,
	att_name = '',
	att_value = ''
) => {
	return new Promise((resolve, reject) => {
		apiGet('GetEncounterTwoClassEncounterDetails', {
			m_conceptId: conceptId,
			m_subdocumentId: subdocumentId,
			m_studyArm: datatype,
			is_event: isEvent,
			m_concept_attribute_name: att_name,
			m_concept_attribute_value: att_value,
		})
			.then((res) => {
				resolve(res?.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getStudyDetail = (study_id) => {
	return new Promise((resolve, reject) => {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const setIsLoading = (loading) => {
	store.dispatch({
		type: actionTypes.IS_LOADING,
		payload: loading,
	});
};

export const updateStudyValidation = (updatedStudyValidationValues) => {
	store.dispatch({
		type: actionTypes.UPDATE_STUDY_VALIDITY,
		payload: updatedStudyValidationValues,
	});
};

export const setSideNavList = (list) => {
	store.dispatch({
		type: actionTypes.SET_SIDE_NAVLIST,
		payload: list,
	});
};
export const setHistory = (list) => {
	store.dispatch({
		type: actionTypes.SET_HISTORY,
		payload: list,
	});
};
