import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import constants from '../../constants';
import { removeItemFromLocal } from '../../utilities/utils';
import ErrorBoundary from '../../components/ErrorBoundary';
import { clearData, openModal, removeFilterText } from './LogoutHelper';
import localforage from 'localforage';

const Logout = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);

	useImperativeHandle(ref, () => {
		handleClick: handleClick;
	});

	useEffect(() => {
		props.open({
			handleClick: () => handleClick(),
		});
		return () => {
			props.open(null);
		};
	}, []);

	const closeModal = () => {
		setOpen(false);
		openModal('');
	};

	const handleClick = () => {
		setOpen(true);
	};

	const logout = () => {
		clearData()
			.then((res) => {
				removeItemFromLocal('user');
				removeItemFromLocal('userSession');
				removeItemFromLocal('authSecret');
				removeItemFromLocal('newRapidAnnotation');
				removeItemFromLocal('updatedAnnotation');
				removeItemFromLocal('deletedAnnotation');
				window.localStorage.clear();
				window.sessionStorage.clear();
				localforage.clear();
				props.history.push(constants.ROUTE_URL);
			})
			.catch((err) => {
				console.log(err);
			});
		removeFilterText('');
		//SET CLOSE MODAL IN REDUX
		openModal('');
	};

	return (
		<div>
			<ErrorBoundary>
				<ModalComponent
					open={open}
					content={
						<div>
							<div className="jconfirm-content-pane">
								<span style={{ fontSize: 16 }}>
									Are you sure you want to sign out ?
								</span>
							</div>
							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										className="btn-logout"
										type="button"
										onClick={() => closeModal()}
									>
										No
									</button>
									<button
										className="btn-logout"
										type="button"
										onClick={() => logout()}
									>
										Yes
									</button>
								</div>
							</div>
						</div>
					}
					handleClose={closeModal}
				/>
			</ErrorBoundary>
		</div>
	);
});

Logout.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	open: PropTypes.func,
};

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(Logout));
