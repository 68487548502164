import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import { Link } from 'react-router-dom';
import { setQueryParams } from '../../../utilities/utils';
import MessagePopup from '../../../components/MessagePopup/MessagePopup';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { setIsLoading } from '../../../actions/common';
import {
	getSubdocumentById,
	getUserManualAnnotationsWithoutFlag,
	getUserDetails,
	searchConceptsById,
	resolveManualAnnotationConflicts,
} from './DataResolveHelper';
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};
const DataResolve = (props) => {
	const [doc_name, setDocName] = useState('');
	const [doc_id, setDocId] = useState('');
	const [content_type, setContentType] = useState('');
	const [distinctConcept, setDistinctConcept] = useState([]);
	const [annotationList, setAnnotationList] = useState([]);
	const [confirmPopup, setConfirmPopup] = useState({});
	const [togglePopupMessage, setTogglePopupMessage] = useState(
		'Please select at least one conflicting record.'
	);
	let { loading } = props.loading;

	useEffect(() => {
		setIsLoading(true);
		getSubdocumentById(props.match.params?.docId).then((resp) => {
			setDocName(resp.data.data.name_with_date);
			setContentType(resp.data.data.content_type);
		});
	}, []);

	useEffect(() => {
		if (props.redSidenav.userRole.Role) {
			getUserManualAnnotationsWithoutFlag(props.match.params?.docId).then((annotation) => {
				let userIds = [];
				userIds = annotation
					.map((annotate) => {
						return annotate.annotation_details.map((detail) => {
							return detail.user_id;
						});
					})
					.flat()
					.filter((item, index, array) => {
						return array.indexOf(item) == index;
					})
					.join(',');

				getUserDetails(userIds)
					.then((res) => {
						let userList = res.data.data;
						let annotations = annotation;
						let distinctConcept = [
							...new Set(annotations.map((item) => item.concept_id)),
						];

						annotations.map((annotatioDetail, detailIndex) => {
							annotations[detailIndex].sequence = detailIndex;
							annotatioDetail.annotation_details.map((annotation, annIndex) => {
								let user = userList.filter(
									(item) => item.user_id == annotation.user_id
								)[0];
								let userName = `${user.first_name  } ${  user.last_name}`;
								annotations[detailIndex].annotation_details[annIndex].userName =
									userName;
							});
						});
						searchConceptsById(distinctConcept).then((criteriaConcepts) => {
							setDistinctConcept(criteriaConcepts);
							setAnnotationList(annotations);
						});
					})
					.finally(() => {
						setIsLoading(false);
					});
			});
		}
	}, [props.redSidenav.userRole]);

	const redirectToDocument = (annotation) => {
		let { docId } = props.match.params;
		return (
			`${constants.ROUTE_URL 
				}/dashboard/document/${ 
				docId 
				}?route_annotation_id=${ 
				annotation.annotation_id}` || ''
		);
	};

	const checkRadio = (annotation, list, index) => {
		let _annotationList = Object.assign([], annotationList);
		if (index != 1) {
			_annotationList[list.sequence].annotation_details[0].isChecked =
				!_annotationList[list.sequence].annotation_details[0].isChecked;
			_annotationList[list.sequence].annotation_details[1].isChecked =
				!_annotationList[list.sequence].annotation_details[0].isChecked;
		} else {
			_annotationList[list.sequence].annotation_details[1].isChecked =
				!_annotationList[list.sequence].annotation_details[1].isChecked;
			_annotationList[list.sequence].annotation_details[0].isChecked =
				!_annotationList[list.sequence].annotation_details[1].isChecked;
		}
		setAnnotationList(_annotationList);
	};

	const saveClicked = () => {
		let resolveAnnotationsList = [];

		annotationList.map((concept) => {
			let selected_annotation = '';
			let annotationList = [];
			concept.annotation_details.map((items) => {
				if (items.isChecked) {
					selected_annotation = items.annotation_id;
				} else {
					annotationList.push(items.annotation_id);
				}
			});

			if (selected_annotation.toString().length > 0) {
				resolveAnnotationsList.push({
					selected_annotation: selected_annotation.toString(),
					annotationList: annotationList.length > 0 ? annotationList.join(',') : '',
				});
			}
		});
		setIsLoading(true);
		if (resolveAnnotationsList.length > 0) {
			resolveManualAnnotationConflicts(resolveAnnotationsList).then((annotation) => {
				setIsLoading(false);
				let { docId } = props.match.params;
				setQueryParams({
					pathname: `${constants.ROUTE_URL  }/dashboard/datareview/${  docId}`,
				});
			});
		} else {
			setIsLoading(false);
			setTogglePopupMessage('Please select at least one conflicting record.');
			confirmPopup?.handleClick();
		}
	};

	const cancelClicked = () => {
		let { docId } = props.match.params;
		setQueryParams({
			pathname: `${constants.ROUTE_URL  }/dashboard/datareview/${  docId}`,
		});
	};
	const rootClosexit = () => {};
	const closePopEvent = () => {};

	const gotoDatadetail = () => {
		props.history.push(`${constants.ROUTE_URL  }/dashboard/document/${  props.match.params.docId}`);
	};

	const sortByUsername = (annotations) => {
		return annotations.sort(function (a, b) {
			var nameA = a.userName.toLowerCase(),
				nameB = b.userName.toLowerCase();
			if (nameA < nameB)
				//sort string ascending
				return -1;
			// if (nameA > nameB)
			//     return 1
			return 0; //default return value (no sorting)
		});
	};

	return (
		<div className="DataReview">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>
			<div className="document-header-container">
				<span> Resolve manual annotation conflicts: </span>
				<span style={{ textDecoration: 'none' }} onClick={gotoDatadetail} className="hyperlink">
					{doc_name}
				</span>
			</div>
			{distinctConcept.map((concept) => {
				const conceptExits = annotationList.filter((data) => data.concept_id == concept.id);
				return (
					<React.Fragment>
						{conceptExits[0] && (
							<div className="section-header">
								{concept.text} ({concept.sab} {concept.code}){' '}
							</div>
						)}
						{annotationList &&
							annotationList
								.filter((item) => item.concept_id == concept.id)
								.map((list, annotationIndex) => {
									list.annotation_details = sortByUsername(
										list.annotation_details
									);
									let annotationOne = list.annotation_details[0];
									let annotationTwo = list.annotation_details[1];
									return (
										<React.Fragment>
											<div className="alt-bg">
												<div className="group-one-study">
													{annotationOne.isChecked ? (
														<span
															style={{ marginRight: 10 }}
															onClick={() =>
																checkRadio(annotationOne, list, 0)
															}
															className="icon_radio_checked"
														></span>
													) : (
														<span
															style={{ marginRight: 10 }}
															onClick={() =>
																checkRadio(annotationOne, list, 0)
															}
															className="icon_radio_unchecked"
														></span>
													)}
													<span>{annotationOne.userName}</span>
													{': '}
													<Link
														className="hyperlink"
														to={redirectToDocument(annotationOne)}
													>
														{annotationOne.is_present && (
															<span>
																{annotationOne.is_experienced
																	? ''
																	: 'No '}
															</span>
														)}
														<span>{concept.text}</span>
														{annotationOne.annotation_type != null && (
															<span>
																{annotationOne.is_present
																	? ''
																	: ' not mentioned '}
															</span>
														)}
														<span>
															{annotationOne.annotation_type == null
																? ' not mentioned '
																: ''}
														</span>
													</Link>
												</div>
											</div>
											<div className="alt-bg">
												<div className="group-two-study ">
													{annotationTwo.isChecked ? (
														<span
															style={{ marginRight: 10 }}
															onClick={() =>
																checkRadio(annotationTwo, list, 1)
															}
															className="icon_radio_checked"
														></span>
													) : (
														<span
															style={{ marginRight: 10 }}
															onClick={() =>
																checkRadio(annotationTwo, list, 1)
															}
															className="icon_radio_unchecked"
														></span>
													)}
													<span>{annotationTwo.userName}</span>
													{': '}
													<Link
														className="hyperlink"
														to={redirectToDocument(annotationTwo)}
													>
														{annotationTwo.is_present && (
															<span>
																{annotationTwo.is_experienced
																	? ''
																	: 'No '}
															</span>
														)}
														<span>{concept.text}</span>
														{annotationTwo.annotation_type != null && (
															<span>
																{annotationTwo.is_present
																	? ''
																	: ' not mentioned '}
															</span>
														)}
														<span>
															{annotationTwo.annotation_type == null
																? ' not mentioned '
																: ''}
														</span>
													</Link>
												</div>
											</div>
										</React.Fragment>
									);
								})}
					</React.Fragment>
				);
			})}
			<div className="datarapid-buttoncontainer">
				<div className="col-sm-6 text-right trialButton addNewTrial">
					<button type="button" className="trialArm" onClick={cancelClicked}>
						Cancel
					</button>
					<button type="button" className="trialArm" onClick={saveClicked}>
						Save
					</button>
				</div>
			</div>
			<ErrorBoundary>
				<MessagePopup
					rootClosexit={rootClosexit}
					open={(c) => {
						if (c?.handleClick) {
							setConfirmPopup({ handleClick: c?.handleClick });
						}
					}}
					text={togglePopupMessage}
					confirmClick={() => closePopEvent}
				/>
			</ErrorBoundary>
		</div>
	);
};

DataResolve.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.any,
		}),
	}),
	redSidenav: PropTypes.shape({
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataResolve);
