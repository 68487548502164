/* istanbul ignore file */
import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';
import actions from '../../actions';
import {
	getUserEntityCounts,
	getUserEntityCountsAnnotator,
} from '../../actions/patientActions';
/****************************************
 * Function Name: setTotalPatients
 * Description: Set total number of patients for pagination.
 * @param number Number - Total number of patients.
 ****************************************/
export function setTotalPatients(number) {
	store.dispatch({
		type: actionTypes.SET_TOTAL_PATIENTS,
		payload: number,
	});
}
/** **************************************\/'/'
 * ]\]
 * Function Name: getStudyDetail
 * Description: exports an action to get the study details from the backend
 * The details received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export function getStudyDetail(study_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

 export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// }
/****************************************
 * Function Name: getEvent
 * Description: exports an action to get the Events
 * from the backend.
 * The data received is set in the reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getEvent() {
	return new Promise(function (resolve, reject) {
		apiGet('GetEvent', {
			eventType: 'outcome',
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_EVENT,
					payload: res.data.data.reverse(),
				});
				resolve(res.data.data.reverse());
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
/****************************************
 * Function Name: getCohortList
 * Description: exports an action to get the cohort list data from the backend
 * The data received gets set in the cohorts reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getCohortList(params) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}

export const getDocumentIconsInfo = (docs, role) => {
	if (role == roleAccess.ANNOTATOR) {
		return new Promise((resolve, reject) => {
			docs.forEach((doc, index) => {
				doc.cohorts = [];
			});
			resolve(docs);
		});
	} else {
		const promiseArr = [];
		docs.forEach((doc) => {
			const m_DocIdList = doc.document_detail.map((subDoc) => subDoc.subdocument_id).join();
			promiseArr.push(apiGet('GetCohortListForDocument', { m_DocIdList }));
		});

		return new Promise((resolve, reject) => {
			Promise.all(promiseArr).then((res) => {
				let mAIndex = 1,
					conceptIndex = 0;
				docs.forEach((doc, index) => {
					doc.cohorts = filterCohorts(res[conceptIndex].data.data);
					conceptIndex += 1;
				});
				resolve(docs);
			});
		});
	}
};

const filterCohorts = (conceptList) => {
	const map = new Map();
	const uniqueCohorts = [];
	for (const concept of conceptList) {
		if (!map.has(concept.cohort_id)) {
			map.set(concept.cohort_id, true);
			uniqueCohorts.push(concept);
		}
	}
	uniqueCohorts.forEach((cohort) => {
		cohort.concepts = [];
	});
	conceptList.forEach((concept) => {
		const cohortIndex = uniqueCohorts.findIndex(
			(cohort) =>
				cohort.cohort_id === concept.cohort_id && cohort.patient_id == concept.patient_id
		);
		if (cohortIndex !== -1) {
			uniqueCohorts[cohortIndex].concepts.push(concept);
		}
	});

	return uniqueCohorts;
};

export const getDocEntityCounts = (docs) => {
	const promiseArr = [];
	docs.forEach((doc) => {
		const subdocumentIds = doc.document_detail.map((subDoc) => subDoc.subdocument_id).join();
		promiseArr.push(apiGet('GetUserEntityCounts', { subdocumentIds }));
	});
	return new Promise((resolve, reject) => {
		Promise.all(promiseArr).then((res) => {
			docs.forEach((doc, index) => {
				doc.counts = res[index].data.data;
			});
			resolve(docs);
		});
	});
};

export const getPatientDocuments = (patientId, orgId, searchText, isAnnotator) => {
	const query = {
		patient_id: patientId,
	};
	if (orgId) {
		query.organization_id = orgId;
	}
	if (searchText) {
		query.m_searchString = searchText;
	}
	let url = 'GetDocuments';
	if (isAnnotator) {
		url = 'GetDocuments';
	}
	return new Promise((resolve, reject) => {
		apiGet(url, query).then(
			(res) => {
				resolve(res.data.data);
			},
			(err) => {
				console.error(err);
			}
		);
	});
};
export const getEntityCounts = (orgId) => {
	return new Promise((resolve, reject) => {
		const queryParams = getQueryForCounts(orgId);
		queryParams.isPatientList = true;
		getUserEntityCounts(queryParams)().then((res) => {
			resolve(res);
		});
	});
};

export const getEntityCountsAnnotator = (orgId) => {
	return new Promise((resolve, reject) => {
		const queryParams = getQueryForCounts(orgId);
		queryParams.isPatientList = true;
		getUserEntityCountsAnnotator(queryParams)().then((res) => {
			resolve(res);
		});
	});
};
export const getEncounterMulticlassUserEntityCounts = (studyId = '', conceptId = '') => {
	return new Promise((resolve, reject) => {
		apiGet('GetEncounterMulticlassUserEntityCounts', {
			m_studyId: studyId,
			m_conceptId: conceptId,
		})
			.then((res) => {
				resolve(res?.data?.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
export const getPatientMulticlassUserEntityCounts = (
	studyId = '',
	conceptId = '',
	cohortCriteria = 'All'
) => {
	const formatCriteriaName = cohortCriteria.split('-').join(' ');
	return new Promise((resolve, reject) => {
		apiGet('GetPatientMulticlassUserEntityCounts', {
			m_studyId: studyId,
			m_conceptId: conceptId,
			m_cohort_criteria: formatCriteriaName,
		})
			.then((res) => {
				resolve(res?.data?.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
export const getEncounterTwoclassUserEntityCounts = (
	studyId = '',
	conceptId = '',
	is_event,
	concept_attribute_name,
	concept_attribute_value,
	isConcept
) => {
	let withConcept = {};
	if (isConcept === 'true') {
		withConcept = {
			m_conceptId: conceptId,
			m_concept_attribute_name: concept_attribute_name,
			m_concept_attribute_value: concept_attribute_value,
		};
	}
	return new Promise((resolve, reject) => {
		apiGet('GetEncounterTwoclassUserEntityCounts', {
			m_studyId: studyId,
			m_is_event: is_event,
			...withConcept,
		})
			.then((res) => {
				resolve(res?.data?.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

export const getQueryForCounts = (orgId) => {
	const query = {};

	if (orgId) {
		query.organizationId = orgId;
	}
	// SPLIT URL STRING BASED ON (?, &, = )
	const params = window.location.search.split(/[?&=]/);

	if (params.indexOf('study_id') !== -1) {
		if (params.indexOf('study_id') !== -1) {
			query.m_studyId = params[params.indexOf('study_id') + 1];
		}
	} else if (params.indexOf('cohort_id') !== -1) {
		query.m_cohortId = params[params.indexOf('cohort_id') + 1];

		if (params.indexOf('outcome_id') !== -1) {
			query.outcomeId = params[params.indexOf('outcome_id') + 1];
		}
	} else if (params.indexOf('organization_id') !== -1) {
		query.Organization_id = params[params.indexOf('organization_id') + 1];
	}
	//orgId and patientId can be together
	if (params.indexOf('patient_id') !== -1) {
		query.patientId = params[params.indexOf('patient_id') + 1];
	}
	if (params.indexOf('concept_id') !== -1) {
		query.m_conceptId = params[params.indexOf('concept_id') + 1];
	}
	if (params.indexOf('flagForPatientWithConcept') !== -1) {
		query.m_searchByPatient = params[params.indexOf('flagForPatientWithConcept') + 1];
	}
	if (params.indexOf('event_id') !== -1) {
		query.m_eventId = params[params.indexOf('event_id') + 1];
	}
	if (params.indexOf('forResult') !== -1) {
		query.forResult = params[params.indexOf('forResult') + 1];
	}
	if (params.indexOf('m_conceptSeverity') !== -1) {
		query.m_conceptSeverity = params[params.indexOf('m_conceptSeverity') + 1];
	}

	return query;
};
