import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import constants from '../../../constants';
import Loading from 'react-loading-spinner';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Copyright from '../../../components/Copyright/Copyright';
import SortComponent from '../../../common/SortComponent/SortComponent';
import { Link } from 'react-router-dom';
import { setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import { ConvertToLocale, pValueFormat } from '../../../utilities/utils';
import {
	getEncounterLevelOutcomeScore,
	setIsLoading,
	updateStudyValidation,
	setSideNavList,
	getStudyDetail,
	getPatientLevelProgression,
	getStudyValidationCriteria,
} from './StudyValidityOutcomeScoreHelper';

const propTypes = {
	loading: PropTypes.any,
	match: PropTypes.shape({
		params: PropTypes.shape({
			studyId: PropTypes.any,
		}),
	}),
	study: PropTypes.shape({
		studyDetail: PropTypes.shape({
			study_name: PropTypes.any,
		}),
	}),
};

import trialIcon from '../../../images/trial_icon.png';

const headerData = {
	title: 'Validity',
	list: 'detail',
	img: <img src={trialIcon} className={'App-TrialList'} alt={'Trial List'} />,
};

const StructUnStructHeader = [
	'Structured matches',
	'Structured mismatches',
	'Structured accuracy',
	'Inferred matches',
	'Inferred mismatches',
	'Inferred accuracy',
	'Inferred - structured p-value',
	'Encounters with concept',
	'Total encounters',
	'Patients with concept',
	'Total patients',
];
const UnStructHeader = [
	'Inferred matches',
	'Inferred mismatches',
	'Inferred accuracy',
	'Inferred - structured p-value',
	'Encounters with concept',
	'Total encounters',
	'Patients with concept',
	'Total patients',
];

const StructHeader = [
	'Structured matches',
	'Structured mismatches',
	'Structured accuracy',
	'Inferred - structured p-value',
	'Encounters with concept',
	'Total encounters',
	'Patients with concept',
	'Total patients',
];

const StudyValidityOutcomeScore = (props) => {
	let [sortOrder, setSortOrder] = useState('asc');
	let [columnName, setColumnName] = useState('concept_name');
	const [outcomeScoreData, setOutcomeScoreData] = useState([]);
	const { studyId, resolution } = props.match.params;
	let criteria = getStudyValidationCriteria(studyId);
	let subTitle = criteria?.column?.label || 'Accuracy';
	const { studyDetail } = props.study;
	let { loading } = props.loading;
	let showUnstructured = false;
	let showStructured = false;
	let showBoth = false;

	if (criteria.dataType) {
		showBoth = criteria.dataType.value == 3 ? true : false;
		showUnstructured = criteria.dataType.value == 1 ? true : showBoth ? true : false;
		showStructured = criteria.dataType.value == 2 ? true : showBoth ? true : false;
	} else {
		showUnstructured = true;
		showStructured = false;
	}

	useEffect(() => {
		setIsLoading(true);
		updateStudyValidation([]);
		setSideNavList([]);
		setFilterLabel({});
		getStudyDetail(studyId).then((studyDetail) => {
			let filterLabel = {
				page: 'studyvalidity',
				options: [
					{
						filterLabel: 'Study',
						name: studyDetail.study_name,
						fixed: true,
					},
				],
			};
			setFilterLabel(filterLabel);
			if (resolution == 'encounter') {
				getEncounterLevelOutcomeScore(studyId)
					.then((res) => {
						setIsLoading(false);
						setOutcomeScoreData(res?.data || []);
					})
					.catch((err) => {
						setIsLoading(false);
						console.log(err);
					});
			} else if (resolution == 'patient') {
				getPatientLevelProgression(studyId)
					.then((res) => {
						setIsLoading(false);
						setOutcomeScoreData(res?.data || []);
					})
					.catch((err) => {
						setIsLoading(false);
						console.log(err);
					});
			}
		});
	}, []);

	useEffect(() => {
		const _outcomeScoreData = Object.assign([], outcomeScoreData);
		if (sortOrder === 'asc') {
			_outcomeScoreData.sort((a, b) => {
				let fa = a?.conceptName?.toLowerCase(),
					fb = b?.conceptName?.toLowerCase();

				if (fa < fb) {
					return -1;
				}
			});
		} else {
			_outcomeScoreData.sort((a, b) => {
				let fa = a?.conceptName?.toLowerCase(),
					fb = b?.conceptName?.toLowerCase();

				if (fa > fb) {
					return -1;
				}
			});
		}
		setOutcomeScoreData(_outcomeScoreData);
	}, [sortOrder]);

	const sortASC = (_columnName, _order) => {
		setSortOrder(_order);
		setColumnName(_columnName);
	};
	const ConceptCol = () => (
		<Fragment>
			<td>
				<div className="first" onClick={() => sortASC('concept_name')}>
					<span>Concepts</span>
					<SortComponent
						columnName={'concept_name'}
						sortOrder={sortOrder}
						sortASC={sortASC}
						id={'concept_name'}
						sortedColumnName={columnName}
					/>
				</div>
			</td>
			{resolution == 'patient' && (
				<td>
					<div className="first">
						<span>Cohort criteria</span>
					</div>
				</td>
			)}
		</Fragment>
	);
	const TableHeader = () => {
		let header = {};
		if (showBoth) {
			header = (
				<tr>
					<ConceptCol />
					{StructUnStructHeader.map((data, index) => (
						<td key={index}>
							<span>{data}</span>
						</td>
					))}
				</tr>
			);
		} else if (showStructured) {
			header = (
				<tr>
					<ConceptCol />
					{StructHeader?.map((data, index) => (
						<td key={index}>
							<span>{data}</span>
						</td>
					))}
				</tr>
			);
		} else if (showUnstructured) {
			header = (
				<tr>
					<ConceptCol />
					{UnStructHeader?.map((data, index) => (
						<td key={index}>
							<span>{data}</span>
						</td>
					))}
				</tr>
			);
		}
		return header;
	};

	const getDataUrl = (data, isConcept = true) => {
		const is_event = data?.is_event || 'false';
		const formatCriteriaName = data.cohortCriteria || 'All';
		const concept_attribute_name = data.concept_attribute_name || '0';
		const concept_attribute_value = data.concept_attribute_value || '0';
		return (
			`${constants.ROUTE_URL 
			}/dashboard/datalist/${ 
			resolution 
			}/${ 
			studyId 
			}/${ 
			data?.conceptId 
			}/${ 
			isConcept 
			}/${ 
			is_event 
			}/${ 
			concept_attribute_name 
			}/${ 
			concept_attribute_value 
			}/${ 
			formatCriteriaName.split(' ').join('-')}`
		);
	};

	const getPatientUrl = (data, isConcept = true) => {
		const is_event = data?.is_event || 'false';
		const formatCriteriaName = data?.cohortCriteria || 'All';
		const concept_attribute_name = data?.concept_attribute_name || '0';
		const concept_attribute_value = data?.concept_attribute_value || '0';

		return (
			`${constants.ROUTE_URL 
			}/dashboard/patientlist/${ 
			resolution 
			}/${ 
			studyId 
			}/${ 
			data?.conceptId 
			}/${ 
			isConcept 
			}/${ 
			is_event 
			}/${ 
			concept_attribute_name 
			}/${ 
			concept_attribute_value 
			}/${ 
			formatCriteriaName.split(' ').join('-')}`
		);
	};

	const getLink = (data, classification = '0') => {
		const formatCriteriaName = data.cohortCriteria || 'All';
		let level = '';
		if (resolution == 'encounter' && data?.matchLevel) {
			level = `-(${data?.matchLevel})`;
		}
		if (resolution == 'patient') {
			level = `-(progression)`;
		}

		const concept_name = data?.conceptName + level?.split(' ').join('-') || '';

		let path = '';
		if (data.conceptId) {
			const matchLevel = data?.matchLevel ? data?.matchLevel?.replace(/ /g, '') : 'false';
			path =
				`${constants.ROUTE_URL 
				}/dashboard/studyvalidityoutcomescoredetail/${ 
				criteria?.resolution?.label?.toLowerCase() 
				}/${ 
				studyId 
				}/${ 
				data.conceptId 
				}/${ 
				matchLevel || 'false' 
				}/${ 
				classification 
				}/${ 
				formatCriteriaName.split(' ').join('-') 
				}/${ 
				concept_name}`;
		}
		return path;
	};

	const TableBody = () => {
		return (
			<Fragment>
				{outcomeScoreData.map((data, index) => (
					<tr key={index}>
						<td>
							<div className="first">
								{data?.conceptName}
								{resolution == 'encounter' && data?.matchLevel
									? ` (${data?.matchLevel})`
									: ''}
								{resolution == 'patient' ? ` (progression)` : ''}
							</div>
						</td>
						{resolution == 'patient' && (
							<td>
								<div className="first">{data?.cohortCriteria}</div>
							</td>
						)}
						{(showBoth || showStructured) && (
							<td>
								{data.structuredMatches === 'NA' ? (
									'NA'
								) : (
									<Link
										to={getLink(data, 'MATCH')}
										className="study-validity-navlink"
									>
										{data?.structuredMatches || '0'}
										{/* Structured matches */}
									</Link>
								)}
							</td>
						)}
						{(showBoth || showStructured) && (
							<td>
								{data.structuredMismatches === 'NA' ? (
									'NA'
								) : (
									<Link
										to={getLink(data, 'MISMATCH')}
										className="study-validity-navlink"
									>
										{data?.structuredMismatches || '0'}
										{/* Structured mismatches */}
									</Link>
								)}
							</td>
						)}
						{(showBoth || showStructured) && (
							<td>
								{data.structuredAccuracy === 'NA' ? (
									'NA'
								) : (
									<Link to={getLink(data)} className="study-validity-navlink">
										{(data?.structuredAccuracy * 100)?.toFixed(2)}%
										{/* Structured accuracy */}
									</Link>
								)}
							</td>
						)}
						{(showBoth || showUnstructured) && (
							<td>
								{data.inferredMatches === 'NA' ? (
									'NA'
								) : (
									<Link
										to={getLink(data, 'MATCH')}
										className="study-validity-navlink"
									>
										{ConvertToLocale(data?.inferredMatches) || '0'}
										{/* inferred matches */}
									</Link>
								)}
							</td>
						)}
						{(showBoth || showUnstructured) && (
							<td>
								{data.inferredMismatches === 'NA' ? (
									'NA'
								) : (
									<Link
										to={getLink(data, 'MISMATCH')}
										className="study-validity-navlink"
									>
										{ConvertToLocale(data?.inferredMismatches) || '0'}
										{/* inferred mismatches */}
									</Link>
								)}
							</td>
						)}
						{(showBoth || showUnstructured) && (
							<td>
								{data.inferredAccuracy === 'NA' ? (
									'NA'
								) : (
									<Link to={getLink(data)} className="study-validity-navlink">
										{(data?.inferredAccuracy * 100)?.toFixed(2)}%
										{/* inferred accuracy */}
									</Link>
								)}
							</td>
						)}
						<td>
							{data.inferredPValue === 'NA' ? (
								'NA'
							) : (
								<div>{pValueFormat(data.inferredPValue) || '0'}</div>
							)}
							{/* Inferred-structured - p-value */}
						</td>
						<td>
							{data.encountersWithConcept === 'NA' ? (
								'NA'
							) : (
								<Link to={getDataUrl(data)} className="study-validity-navlink">
									{ConvertToLocale(data.encountersWithConcept) || '0'}
								</Link>
							)}
							{/* Encounters with concept */}
						</td>
						<td>
							{data.totalEncounters === 'NA' ? (
								'NA'
							) : (
								<Link
									to={getDataUrl(data, false)}
									className="study-validity-navlink"
								>
									{ConvertToLocale(data?.totalEncounters) || '0'}
								</Link>
							)}

							{/* Total encounters */}
						</td>
						<td>
							{data.patientsWithConcept === 'NA' ? (
								'NA'
							) : (
								<Link to={getPatientUrl(data)} className="study-validity-navlink">
									{ConvertToLocale(data.patientsWithConcept) || '0'}
								</Link>
							)}
							{/* Patients with concept */}
						</td>
						<td>
							{data.totalPatients === 'NA' ? (
								'NA'
							) : (
								<Link
									to={getPatientUrl(data, false)}
									className="study-validity-navlink"
								>
									{ConvertToLocale(data.totalPatients) || '0'}
								</Link>
							)}

							{/* Total patients */}
						</td>
					</tr>
				))}
			</Fragment>
		);
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					studyId={props.match.params.studyId}
					{...props}
				/>
			</ErrorBoundary>
			<div>
				<h3 className="v-cohort-name">
					{subTitle}: {studyDetail.study_name}
				</h3>
			</div>
			{loading && (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			)}
			<div className="study-validity-details-tbl-container">
				<table className="tbl-strip">
					<thead className="tbl-head">
						<TableHeader />
					</thead>
					<tbody>
						<TableBody />
					</tbody>
				</table>
			</div>
			<Copyright />
		</div>
	);
};
StudyValidityOutcomeScore.propTypes = propTypes;

function mapStateToProps(state) {
	return { study: state.redStudy, loading: state.loading };
}
export default connect(mapStateToProps)(StudyValidityOutcomeScore);
