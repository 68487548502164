import React from 'react';
import { Tab } from 'react-bootstrap';

const TabComponent = ({ title, eventKey, children, tabClassName }) => (
	<Tab eventKey={eventKey} title={title} tabClassName={tabClassName}>
		{children}
	</Tab>
);

export default TabComponent;
