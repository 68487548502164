/* istanbul ignore file */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Auth0Login from './containers/Login/Auth0Login/Auth0Login';
import Auth0Step2Verification from './containers/Login/OtpVerification/Auth0Step2Verification';
import CodeVerification from './containers/CodeVerification/CodeVerification';
import ForgotPassword from './containers/Login/ForgotPassword/ForgotPassword';
import Dashboard from './containers/Dashboard/Dashboard';
import constants from './constants';
import { getObjectFromLocal, setObjectToLocal, removeItemFromLocal } from './utilities/utils';
import store from './store';

import './style/style.css';
import history from './common/CreateBrowserHistory/CreateBrowserHistory';
import { roleAccess } from './utilities/AppConstants';
import localforage from 'localforage';

const routeUrl = constants.ROUTE_URL.length > 0 ? constants.ROUTE_URL : '';

const pathUrl = constants.PATH_URL;

function checkAuth(nextState, replace) {
	// Check the length of the browser history list.
	const result = window.history.length;
	if (result != 1) {
		const user = getObjectFromLocal('user');
		const userSession = sessionStorage.getItem('userSession');
		if (!user || !user.email || !userSession) {
			// Removing local and session storage variables after user logs off or the browser/tab closes.
			removeItemFromLocal('user');
			removeItemFromLocal('authSecret');
			sessionStorage.removeItem('userSession');
			window.document.cookie =
				'JSESSIONID=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/pathUrl;';
			replace({
				pathname: routeUrl,
			});
		}
	} else {
		sessionStorage.setItem('userSession', 'Y');
	}
}


function handleUpdate() {
	window.scrollTo(0, 0);
}

function isLoggeIn(props) {

	const userSession = sessionStorage.getItem('userSession');
	const userLocal = getObjectFromLocal('user');
	if (!userLocal || !userLocal.email || !userSession) {
		sessionStorage.clear();
		localStorage.clear();
		localforage.clear();
		return (
			<Auth0Login {...props}/>
		)
	} else {
		const element = document.getElementsByTagName('body')[0];
		element?.classList?.remove('bg-image');
		let userRole = getObjectFromLocal('userRole');
		if (userRole === roleAccess.ANNOTATOR) {
			return (
				<Redirect
					to={{
						pathname: '/dashboard/dataworkqueue',
					}}
				/>
			);
		} else {
			return (
				<Redirect
					to={{
						pathname: '/dashboard/studylist',
					}}
				/>
			);
		}
	}
}

function initiateDB (){
	localforage.config({
		driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
		name        : 'verantos',
		version     : 1.0,
		// size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
		storeName   : 'verantosDB', // Should be alphanumeric, with underscores.
		description : 'some description'
	});
}

render(
	<Provider store={store}>
		<Router history={history} onUpdate={() => handleUpdate()}>
			<Switch>
				{
					initiateDB()
				}

				<Route
					exact
					path={routeUrl + '/'}
					component={(props) => {
						return isLoggeIn(props);
					}}
				/>
				<Route exact path={routeUrl + '/step2'} component={Auth0Step2Verification} />
				<Route exact path={routeUrl + '/code'} component={CodeVerification} />
				<Route exact path={routeUrl + '/forgotpassword'} component={ForgotPassword} />
				<Route path={routeUrl + '/dashboard'} component={Dashboard} onEnter={checkAuth} />
			</Switch>
		</Router>
	</Provider>,
	document.getElementById('root')
);
