import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import constants from '../../../constants';
import actions from '../../../actions';
import Loading from 'react-loading-spinner';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Copyright from '../../../components/Copyright/Copyright';
import SortComponent from '../../../common/SortComponent/SortComponent';
import { Link } from 'react-router-dom';
import {
	getEncounterLevelOutcomeScoreDetail,
	getPatientLevelProgressionDetails,
	setIsLoading,
	updateStudyValidation,
	setSideNavList,
	setHistory,
} from './StudyValidityOutcomeScoreDetailsHelper';
import { setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import trialIcon from '../../../images/trial_icon.png';

const headerData = {
	title: 'Validity',
	list: 'detail',
	img: (
		<img
			src={trialIcon}
			className={'App-TrialList'}
			alt={'Trial List'}
		/>
	),
};

const StudyValidityOutcomeScoreDetails = (props) => {
	const {
		studyId,
		conceptId,
		resolution,
		exactMatch,
		classification,
		cohortCriteria,
		concept_name,
	} = props.match.params;
	let criteria = actions.getStudyValidationCriteria(studyId);
	let subTitle = (criteria.column && criteria.column.label) || 'Accuracy';
	let { studyDetail } = props.study;
	let { loading } = props.loading;
	const [outcomeScoreDetailsData, setOutcomeScoreDetailsData] = useState([]);
	let [columnName, setColumnName] = useState('inferredClassification');
	let [sortOrder, setSortOrder] = useState('asc');
	let [type, setColType] = useState('asc');

	useEffect(() => {
		setIsLoading(true);
		updateStudyValidation([]);
		setSideNavList([]);
		if (resolution == 'encounter') {
			getEncounterLevelOutcomeScoreDetail(
				conceptId,
				exactMatch.includes('exact'),
				classification
			)
				.then((res) => {
					setOutcomeScoreDetailsData(formatData(res?.data || []));
					setIsLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setIsLoading(false);
				});
		} else if (resolution == 'patient') {
			const formatCriteriaName = cohortCriteria.split('-').join(' ');
			const classificationName = classification === '0' ? '' : classification;
			getPatientLevelProgressionDetails(conceptId, formatCriteriaName, classificationName)
				.then((res) => {
					setOutcomeScoreDetailsData(formatData(res?.data || []));
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err);
				});
		}
		props.dispatch(actions.getStudyDetail(studyId)).then((_studyDetail) => {
			let label = 'Concept';
			let filterLabel = {
				page: 'studyvalidity',
				options: [
					{
						filterLabel: 'Study',
						name: _studyDetail?.study_name,
						fixed: false,
					},
					{
						filterLabel: label,
						name: concept_name?.split('-').join(' '),
					},
				],
			};
			setFilterLabel(filterLabel);
			studyDetail = _studyDetail;
		});
	}, []);

	const formatData = (data) => {
		const sortedData = data.sort((a, b) => {
			let fa = a?.inferredClassification?.toLowerCase(),
				fb = b?.inferredClassification?.toLowerCase();

			if (fa < fb) {
				return -1;
			}
		});
		return sortedData;
	};

	useEffect(() => {
		const _outcomeScoreDetailsData = Object.assign([], outcomeScoreDetailsData);
		if (type === 'patient') {
			if (sortOrder === 'asc') {
				_outcomeScoreDetailsData.sort((a, b) => {
					let fa = a?.patient_identifier?.toLowerCase(),
						fb = b?.patient_identifier?.toLowerCase();

					if (fa < fb) {
						return -1;
					}
				});
			} else {
				_outcomeScoreDetailsData.sort((a, b) => {
					let fa = a?.patient_identifier?.toLowerCase(),
						fb = b?.patient_identifier?.toLowerCase();

					if (fa > fb) {
						return -1;
					}
				});
			}
		} else if (type === 'Inferred') {
			if (resolution == 'patient') {
				if (sortOrder === 'asc') {
					_outcomeScoreDetailsData.sort((a, b) => {
						let fa = a?.inferredAnnotation?.toLowerCase(),
							fb = b?.inferredAnnotation?.toLowerCase();

						if (fa < fb) {
							return -1;
						}
					});
				} else {
					_outcomeScoreDetailsData.sort((a, b) => {
						let fa = a?.inferredAnnotation?.toLowerCase(),
							fb = b?.inferredAnnotation?.toLowerCase();

						if (fa > fb) {
							return -1;
						}
					});
				}
			} else {
				if (sortOrder === 'asc') {
					_outcomeScoreDetailsData.sort(
						(a, b) => a.inferredAnnotation - b.inferredAnnotation
					);
				} else {
					_outcomeScoreDetailsData.sort(
						(a, b) => b.inferredAnnotation - a.inferredAnnotation
					);
				}
			}
		} else if (type === 'InferredC') {
			if (sortOrder === 'asc') {
				_outcomeScoreDetailsData.sort((a, b) => {
					let fa = a?.inferredClassification?.toLowerCase(),
						fb = b?.inferredClassification?.toLowerCase();

					if (fa < fb) {
						return -1;
					}
				});
			} else {
				_outcomeScoreDetailsData.sort((a, b) => {
					let fa = a?.inferredClassification?.toLowerCase(),
						fb = b?.inferredClassification?.toLowerCase();

					if (fa > fb) {
						return -1;
					}
				});
			}
		} else if (type === 'RSA') {
			if (resolution == 'patient') {
				if (sortOrder === 'asc') {
					_outcomeScoreDetailsData.sort((a, b) => {
						let fa = a?.referenceStandardAnnotation?.toLowerCase(),
							fb = b?.referenceStandardAnnotation?.toLowerCase();

						if (fa < fb) {
							return -1;
						}
					});
				} else {
					_outcomeScoreDetailsData.sort((a, b) => {
						let fa = a?.referenceStandardAnnotation?.toLowerCase(),
							fb = b?.referenceStandardAnnotation?.toLowerCase();

						if (fa > fb) {
							return -1;
						}
					});
				}
			} else {
				if (sortOrder === 'asc') {
					_outcomeScoreDetailsData.sort(
						(a, b) => a.referenceStandardAnnotation - b.referenceStandardAnnotation
					);
				} else {
					_outcomeScoreDetailsData.sort(
						(a, b) => b.referenceStandardAnnotation - a.referenceStandardAnnotation
					);
				}
			}
		} else {
			if (sortOrder === 'asc') {
				_outcomeScoreDetailsData.sort((a, b) => a.patientId - b.patientId);
			} else {
				_outcomeScoreDetailsData.sort((a, b) => b.patientId - a.patientId);
			}
		}
		setOutcomeScoreDetailsData(_outcomeScoreDetailsData);
	}, [sortOrder, columnName, type]);

	const sortASC = (_columnName, _order, _type) => {
		setSortOrder(_order);
		setColumnName(_columnName);
		setColType(_type);
	};

	const gotoPatientDocument = (isPatient, data) => {
		if (isPatient) {
			setHistory({
				...window.location,
				display_name: `Study detail: ${studyDetail.study_name}`,
			});
		} else {
			setHistory({
				...window.location,
				display_name: `Study detail: ${studyDetail.study_name}`,
			});
			setQueryParams({
				pathname: `${constants.ROUTE_URL}/dashboard/document/${data.doc_id}`,
				query: {
					doc_name: data.doc_name,
				},
			});
		}
	};

	const setHistoryForStudy = () => {
		setHistory(false, studyDetail.study_name);
	};
	const getDocumentUrl = (subdocument_id) => {
		return `${constants.ROUTE_URL}/dashboard/document/${subdocument_id}`;
	};

	const TableHeader = () => {
		let header = (
			<tr>
				<td>
					<div className="first">
						<span>Patients</span>
						<SortComponent
							columnName={'patientId'}
							sortOrder={sortOrder}
							sortASC={(a, b) => sortASC(a, b, 'patient')}
							id={'patientId'}
							columnName={'patientId'}
							sortedColumnName={columnName}
						/>
					</div>
				</td>
				<td>
					<div className="text-left">
						<span>Encounters</span>
						<SortComponent
							columnName={'encounterId'}
							sortOrder={sortOrder}
							sortASC={sortASC}
							id={'encounterId'}
							columnName={'encounterId'}
							sortedColumnName={columnName}
						/>
					</div>
				</td>
				<td>
					<div
						className={
							resolution == 'patient' ? 'study-validity-item-left' : 'text-center'
						}
					>
						<span>Inferred annotation</span>
						<SortComponent
							columnName={'inferredAnnotation'}
							sortOrder={sortOrder}
							sortASC={(a, b) => sortASC(a, b, 'Inferred')}
							id={'inferredAnnotation'}
							columnName={'inferredAnnotation'}
							sortedColumnName={columnName}
						/>
					</div>
				</td>
				<td>
					<div className="text-left">
						<span>Inferred classification</span>
						<SortComponent
							columnName={'inferredClassification'}
							sortOrder={sortOrder}
							sortASC={(a, b) => sortASC(a, b, 'InferredC')}
							id={'inferredClassification'}
							columnName={'inferredClassification'}
							sortedColumnName={columnName}
						/>
					</div>
				</td>
				<td>
					<div
						className={
							resolution == 'patient' ? 'study-validity-item-left' : 'text-center'
						}
					>
						<span>Reference standard annotation</span>
						<SortComponent
							columnName={'referenceStandardAnnotation'}
							sortOrder={sortOrder}
							sortASC={(a, b) => sortASC(a, b, 'RSA')}
							id={'referenceStandardAnnotation'}
							columnName={'referenceStandardAnnotation'}
							sortedColumnName={columnName}
						/>
					</div>
				</td>
			</tr>
		);
		return header;
	};

	const camelCase = (str) => {
		if (isNaN(str) && str !== 'NA') {
			const str2 = str.toLowerCase();
			return str2.charAt(0).toUpperCase() + str2.slice(1);
		}
		return str;
	};

	const TableBody = () => {
		let body = (
			<Fragment>
				{outcomeScoreDetailsData.map((data, index) => (
					<tr key={index}>
						<td>
							<div className="first">
								<Link
									className="hyperlink"
									onClick={() => {
										gotoPatientDocument(true, data);
									}}
									to={
										`${constants.ROUTE_URL}/dashboard/patient/${data.patientId}`
									}
									id="testid-link-4"
								>
									{data.patient_identifier || data.patientId}
								</Link>
							</div>
						</td>
						{(data.encounterId || data.encounter_name) && (
							<td>
								<div className="text-left">
									<Link to={getDocumentUrl(data.encounterId)}>
										{data.encounter_name || data.encounterId}
									</Link>
								</div>
							</td>
						)}
						{data.encounterIds && (
							<td>
								{data.encounterIds[0]?.encounterId ? (
									<div className="text-left">
										<Link
											to={getDocumentUrl(data?.encounterIds[0]?.encounterId)}
										>
											{data.encounterIds[0]?.encounter_name}
										</Link>
										<br />
										<Link
											to={getDocumentUrl(data?.encounterIds[1]?.encounterId)}
										>
											{data.encounterIds[1]?.encounter_name}
										</Link>
									</div>
								) : (
									<Link to={getDocumentUrl(data?.encounterIds[0])}>
										{data?.encounterIds[0]}
									</Link>
								)}
							</td>
						)}
						<td>
							<div
								className={
									resolution == 'patient'
										? 'study-validity-item-left'
										: 'text-center'
								}
							>
								<div>
									{data?.inferredAnnotation === 'NA'
										? 'NA'
										: camelCase(
												data?.inferredAnnotation !== null
													? data?.inferredAnnotation
													: 'NA'
										  )}
								</div>
							</div>
						</td>
						<td>
							<div className="text-left" style={{ marginLeft: '10px' }}>
								<div>
									{data?.inferredClassification === 'NA'
										? 'NA'
										: camelCase(
												data?.inferredClassification !== null
													? data?.inferredClassification
													: 'NA'
										  )}
								</div>
							</div>
						</td>
						<td>
							<div
								className={
									resolution == 'patient'
										? 'study-validity-item-left'
										: 'text-center'
								}
							>
								<div>
									{data?.referenceStandardAnnotation === 'NA'
										? 'NA'
										: camelCase(
												data?.referenceStandardAnnotation !== null
													? data?.referenceStandardAnnotation
													: 'NA'
										  )}
								</div>
							</div>
						</td>
					</tr>
				))}
			</Fragment>
		);
		return body;
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					studyId={props.match.params.studyId}
					{...props}
				/>
			</ErrorBoundary>
			<div>
				<h3 className="v-cohort-name">
					{subTitle}: {studyDetail.study_name}
				</h3>
			</div>
			{loading && (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			)}
			<div className="study-validity-details-tbl-container">
				<table className="tbl-strip">
					<thead className="tbl-head">
						<TableHeader />
					</thead>
					<tbody>
						<TableBody />
					</tbody>
				</table>
			</div>
			<Copyright />
		</div>
	);
};

StudyValidityOutcomeScoreDetails.propTypes = {
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			conceptId: PropTypes.any,
			studyId: PropTypes.any,
		}),
	}),
	study: PropTypes.shape({
		studyDetail: PropTypes.shape({
			study_name: PropTypes.any,
		}),
	}),
};

function mapStateToProps(state) {
	return { study: state.redStudy, loading: state.loading };
}

export default connect(mapStateToProps)(StudyValidityOutcomeScoreDetails);
