import React, { useState, useEffect, lazy, Suspense, Fragment } from 'react';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import Loading from 'react-loading-spinner';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { setQueryParams, setObjectToLocal, getObjectFromLocal } from '../../utilities/utils';
import ErrorBoundary from '../../components/ErrorBoundary';
import Copyright from '../../components/Copyright/Copyright';
import {
	GetCohortDetailsForStudyValidity,
	GetCohortKappaScores,
	getDocumentConceptAnnotationResults,
	getEvent,
	getStudyDetail,
	getUserCohortAnnotationCount,
	searchConceptsById,
	setSideNavList,
	updateStudyValidation,
	setHistoory,
	getStudyValidationCriteria,
} from './StudyValidityHelper';
import { setIsLoading } from '../../actions/common';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import trialIcon from '../../images/trial_icon.png';

const StudyValidityDetails = lazy(() =>
	import(/* webpackChunkName: "StudyValidityDetails" */ './StudyValidityDetails')
);

const headerData = {
	title: 'Validity',
	list: 'detail',
	img: <img src={trialIcon} className={'App-TrialList'} alt={'Trial List'} />,
};
let showUnstructured = false;
let showStructured = false;
let showBoth = false;

const selectedHeader = getObjectFromLocal('filterSelection');
let header = [];

const StudyValidity = (props) => {
	let _criteria = props._criteria ? props._criteria : {};
	const studyId = props.match.params.studyId;
	const values = queryString.parse(props?.location?.search);
	if (values.patientView) {
		_criteria = JSON.parse(window.atob(values.patientView));
	} else if (values.conceptKappaView) {
		_criteria = JSON.parse(window.atob(values.conceptKappaView));
	} else {
		_criteria = props.customCriteria
			? props.customCriteria
			: getStudyValidationCriteria(studyId);
		// props customCriteria is test data
	}

	let [base_cohort, setBaseCohort] = useState('');
	let [criteria, setCriteria] = useState(_criteria);
	let [sortOrder, setSortOrder] = useState('asc');
	let [columnName, setColumnName] = useState('');
	let [patientView, setPatientView] = useState(
		_criteria.row && _criteria.row.value == 2 ? true : false
	);
	let [documentView, setDocumentView] = useState(false);
	let [cohortView, setCohortView] = useState(
		_criteria.row && _criteria.row.value == 3 ? true : false
	);
	let [conceptKappaView, setConceptKappaView] = useState(
		_criteria.row && _criteria.row.value == 1 && _criteria.column.value == 3 ? true : false
	);
	let [conceptView, setConceptView] = useState(
		_criteria.row && _criteria.row.value == 1 && _criteria.column.value == 1 ? true : false
	);
	let [conceptKappaScore, setConceptKappaScore] = useState([]);
	let [selectedCohortId, setSelectedCohortId] = useState(0);
	let [cohortKappa, setCohortKappa] = useState({});
	let [isSearchConceptsByIdLoad, setIsSearchConceptsByIdLoad] = useState(false);

	let { studyDetail, studyConcepts, studyValidity } = props.study;
	let events = props?.redCohort?.events;
	let { loading } = props.loading;
	const [dropDownShow, setDropDownShow] = useState(false);

	if (criteria.dataType) {
		showBoth = criteria.dataType.value == 3 ? true : false;
		showUnstructured = criteria.dataType.value == 1 ? true : showBoth ? true : false;
		showStructured = criteria.dataType.value == 2 ? true : showBoth ? true : false;
	} else {
		showUnstructured = true;
		showStructured = false;
	}
	if (selectedHeader) {
		header = selectedHeader || [];
	} else {
		header = [
			{ name: 'NLP recall', checked: true, display: showUnstructured },
			{ name: 'NLP precision', checked: true, display: showUnstructured },
			{ name: 'NLP F-measure', checked: true, display: showUnstructured },
			{ name: 'NLP concept count', checked: true, display: showUnstructured },
			{ name: 'NLP positive encounters', checked: false, display: false },
			{ name: 'Structured recall', checked: true, display: showStructured },
			{ name: 'Structured precision', checked: true, display: showStructured },
			{ name: 'Structured F-measure', checked: true, display: showStructured },
			{ name: 'Structured concept count', checked: true, display: showStructured },
			{ name: 'Structured positive encounters', checked: false, display: false },
			{ name: 'Inferred recall', checked: true, display: showUnstructured || showBoth },
			{ name: 'Inferred precision', checked: true, display: showUnstructured || showBoth },
			{ name: 'Inferred F-measure', checked: true, display: showUnstructured || showBoth },
			{
				name: 'Inferred concept count',
				checked: true,
				display: showUnstructured || showBoth,
			},
			{ name: 'Inferred positive encounters', checked: false, display: false },
			{ name: 'NLP-structured - p-value', checked: true, display: showUnstructured },
			{ name: 'Inferred-structured - p-value', checked: true, display: true },
			{ name: 'Reference standard encounters', checked: true, display: true },
			{ name: 'Reference standard positive encounters', checked: false, display: false },
			{ name: 'Reference standard concept count', checked: false, display: false },
			{ name: 'Total encounters', checked: true, display: true },
			{ name: 'Reference standard patients', checked: true, display: true },
			{ name: 'Total patients', checked: true, display: true },
		];
	}

	const [commonHeader, setCommonHeader] = useState(header);

	useEffect(() => {
		setIsLoading(true);
		updateStudyValidation([]);
		setSideNavList([]);
		const { studyId } = props.match.params;
		let conceptId = queryString.parse(window.location.search).concept_id;
		let eventId = queryString.parse(window.location.search).event_id;
		let is_result = queryString.parse(window.location.search).is_result;
		let m_conceptSeverity = queryString.parse(window.location.search).m_conceptSeverity;

		getStudyDetail(studyId).then((studyDetail) => {
			let filterLabel = {
				page: 'studyvalidity',
				options: [
					{
						filterLabel: 'Study',
						name: studyDetail.study_name,
						fixed: true,
					},
				],
			};
			setFilterLabel(filterLabel);
			if (criteria?.row?.value != 1) {
				const cohort_id = (criteria.cohort && criteria.cohort.value) || '';
				const cohort_name = (criteria.cohort && criteria.cohort.label) || '';
				const event_id = (criteria.event && criteria.event.value) || 0;
				const concept_id = (criteria.concept && criteria.concept.value) || '';
				const concept_name = (criteria.concept && criteria.concept.label) || '';
				const Is_concept_value_result_type =
					(criteria.concept && criteria.concept.Is_concept_value_result_type) || false;
				let m_studyArmType = '';
				setIsLoading(true);

				if (conceptId) {
					const values = queryString.parse(props?.location?.search);
					if (values.patientView) {
						searchConceptsById(conceptId).then((res) => {
							let label = 'Concept';
							if (is_result) {
								label = 'Concept result';
							}
							let filterLabel = {
								page: 'studyvalidity',
								options: [
									{
										filterLabel: 'Study',
										name: studyDetail.study_name,
										fixed: true,
									},
									{
										filterLabel: label,
										name: res[0].text,
									},
								],
							};
							setFilterLabel(filterLabel);
						});
					}
				} else if (eventId) {
					let filterLabel = {
						page: 'studyvalidity',
						options: [
							{
								filterLabel: 'Study',
								name: studyDetail.study_name,
								fixed: true,
							},
							{
								filterLabel: 'Event',
								name: criteria.event.label,
							},
						],
					};
					setFilterLabel(filterLabel);
				} else if (concept_id && criteria.row.value == 2) {
					let filterLabel = {
						page: 'studyvalidity',
						options: [
							{
								filterLabel: 'Study',
								name: studyDetail.study_name,
								fixed: true,
							},
							{
								filterLabel: 'Cohort',
								name: cohort_name,
								fixed: true,
							},
							{
								filterLabel: 'Concept',
								name: concept_name,
								fixed: true,
							},
						],
					};
					setFilterLabel(filterLabel);
				} else if (cohort_id) {
					let filterLabel = {
						page: 'studyvalidity',
						options: [
							{
								filterLabel: 'Study',
								name: studyDetail.study_name,
								fixed: true,
							},
							{
								filterLabel: 'Cohort',
								name: cohort_name,
								fixed: true,
							},
						],
					};
					setFilterLabel(filterLabel);
				}
				if (criteria?.dataType?.value == 1) {
					m_studyArmType = 'narrative';
				}
				if (criteria?.dataType?.value == 2) {
					m_studyArmType = 'discrete';
				}
				if (criteria?.dataType?.value == 3) {
					m_studyArmType = 'narrativeAndDiscrete';
				}
				if (criteria?.row?.value == 3) {
					setIsLoading(true);
					GetCohortDetailsForStudyValidity(cohort_id)
						.then((cohortKappa) => {
							setCohortKappa(cohortKappa);
						})
						.finally(() => {
							setIsLoading(false);
						});
				} else {
					getDocumentConceptAnnotationResults(
						cohort_id,
						concept_id,
						m_studyArmType,
						event_id,
						Is_concept_value_result_type,
						m_conceptSeverity
					)
						.then((validititydetail) => {
							const studyValidityList = validititydetail || [];
							studyValidityList.map((row) => {
								row.doc_name = row.subdocument[0].subdocument_name;
								row.patient_name = row.patient.patient_identifier;
								row.doc_id = row.subdocument[0].subdocument_id;
								row.patient_id = row.patient.patient_id;
								row.end_position =
									row.document_concept_annotation_result.end_position;
								row.annotation_id =
									row.document_concept_annotation_result.manual_annotation_id;
								row.manual_annotation_result =
									row.document_concept_annotation_result
										.manual_annotation_result || false;
								row.narrative_result =
									row.document_concept_annotation_result.narrative_result ||
									false;
								row.discrete_result =
									row.document_concept_annotation_result.discrete_result || false;
								row.section_number =
									row.document_concept_annotation_result.section_number;
								row.start_position =
									row.document_concept_annotation_result.start_position;
								row.narrative_subdocument_id =
									(row.subdocument.length > 1 &&
										row.subdocument[1].subdocument_id) ||
									'';
								row.narrative_subdocument_name =
									(row.subdocument.length > 1 &&
										row.subdocument[1].subdocument_name) ||
									'';
							});
							if (studyValidityList.length) {
								sortASC('manual_annotation_result', 'dsc');
							}
							setIsLoading(false);
							updateStudyValidation(studyValidityList);
						})
						.finally(() => {
							setIsLoading(false);
						});
				}
			} else {
				getStudyDetail(studyId).then((studyDetail) => {
					let filterLabel = {
						page: 'studyvalidity',
						options: [
							{
								filterLabel: 'Study',
								name: studyDetail.study_name,
								fixed: true,
							},
						],
					};
					setFilterLabel(filterLabel);

					if (criteria && criteria.column && criteria.column.value == 3) {
						getConceptKappaScore(criteria.cohort.value);
					} else {
						let cohirtId = studyDetail.base_cohort;
						setBaseCohort(studyDetail.base_cohort);
						if (criteria && criteria.cohort) {
							cohirtId = criteria.cohort.value;
							let filterLabel = {
								page: 'studyvalidity',
								options: [
									{
										filterLabel: 'Study',
										name: studyDetail.study_name,
										fixed: true,
									},
									{
										filterLabel: 'Cohort',
										name: criteria.cohort.label || '',
										fixed: true,
									},
								],
							};
							setFilterLabel(filterLabel);
						}

						getUserCohortAnnotationCount(cohirtId).then((validititydetail) => {
							const conceptids = [];
							const eventIds = [];
							const eventIds2 = [];

							if (validititydetail[0]) {
								if (
									validititydetail[0]?.unstructured_positive_encounters !==
										null &&
									showUnstructured
								) {
									commonHeader[4].display = true;
								}
								if (
									validititydetail[0]?.structured_positive_encounters !== null &&
									showStructured
								) {
									commonHeader[9].display = true;
								}
								if (
									validititydetail[0]?.inferred_positive_encounters !== null &&
									(showUnstructured || showBoth)
								) {
									commonHeader[14].display = true;
								}
								if (
									validititydetail[0]?.reference_standard_positive_encounters !==
									null
								) {
									commonHeader[18].display = true;
								}
								if (
									validititydetail[0]?.reference_standard_concept_count !== null
								) {
									commonHeader[19].display = true;
								}

								validititydetail.map((items) => {
									if (items.is_event == 'true') {
										eventIds2.push(items.concept_id);
									}
									if (items.concept_id) {
										conceptids.push(items.concept_id);
									}
									if (items.event_id) {
										eventIds.push(items.event_id);
									}
								});
							} else {
								setIsLoading(false);
							}
							if (conceptids.length) {
								setIsSearchConceptsByIdLoad(true);
								searchConceptsById(conceptids.join(',')).then(
									() => {
										setIsSearchConceptsByIdLoad(false);
									},
									() => {
										setIsSearchConceptsByIdLoad(false);
									}
								);
							}
							if (eventIds2.length) {
								getEvent(eventIds2.join(',')).then(() => {
									setIsLoading(false);
								});
							}
						});
					}
				}).finally(() => {
					setIsLoading(false);
				});;
			}
		});
	}, []);

	const getConceptKappaScore = (cohortId) => {
		setIsLoading(true);
		GetCohortKappaScores(cohortId)
			.then((resp) => {
				if (resp) {
					setIsLoading(false);

					resp.map((items) => {
						if (items.PatientWithConceptCount == null) {
							items.PatientWithConceptCount = 0;
						}
						return items;
					});
					setPatientView(false);
					setDocumentView(false);
					setCohortView(false);
					setConceptKappaView(true);
					setConceptView(false);
					setConceptKappaScore(resp);
					setSelectedCohortId(cohortId);
				}
			})
			.finally((res) => {
				setIsLoading(false);
			});
	};

	const conceptKappaCallback = (cohortId) => {
		getConceptKappaScore(cohortId);
	};

	useEffect(() => {
		const tableContainer = document.getElementsByClassName('study-validity-tbl-container')[0];
		if (tableContainer && !dropDownShow) {
			document.getElementsByClassName('study-validity-tbl-container')[0].focus();
		}
	}, [dropDownShow]);

	useEffect(() => {
		if (columnName.includes('KappaConceptView')) {
			const _sortOrder = sortOrder ? sortOrder : 'asc';
			let _columnName = columnName.replace('KappaConceptView_', '');
			const _conceptKappaScore = Object.assign([], conceptKappaScore);
			if (_sortOrder == 'asc') {
				if (_columnName == 'conceptName') {
					_conceptKappaScore.sort(function (a, b) {
						if (a[_columnName]?.toString() < b[_columnName]?.toString()) {
							return -1;
						}
					});
				} else {
					_conceptKappaScore.sort(function (a, b) {
						if (a[_columnName] < b[_columnName]) {
							return -1;
						}
					});
				}
			} else {
				if (_columnName == 'conceptName') {
					_conceptKappaScore.sort(function (a, b) {
						if (a[_columnName]?.toString() > b[_columnName]?.toString()) {
							return -1;
						}
					});
				} else {
					_conceptKappaScore.sort(function (a, b) {
						if (a[_columnName] > b[_columnName]) {
							return -1;
						}
					});
				}
			}
			setConceptKappaScore(_conceptKappaScore);
		}
	}, [sortOrder, columnName]);

	const sortASC = (_columnName, order) => {
		setSortOrder(order);
		setColumnName(_columnName);
	};

	const sortOption = (arr) => {
		let _columnName = '';
		if (columnName.indexOf('KappaConceptView') != -1) {
			_columnName = columnName.replace('KappaConceptView_', '');
		} else {
			if (columnName == 'Encounters') {
				if (criteria.dataType.value == 1) {
					_columnName = 'narrative_subdocument_name';
				} else {
					_columnName = 'doc_name';
				}
			} else {
				_columnName = columnName;
			}
		}
		if (sortOrder == 'asc') {
			try {
				arr.sort(function (a, b) {
					if (a[_columnName]?.toString()) {
						if (a[_columnName]?.toString() < b[_columnName]?.toString()) {
							return -1;
						}
						if (a[_columnName]?.toString() > b[_columnName]?.toString()) {
							return 1;
						}
						if (a?.concept_attribute_value == 'NA' || !a?.concept_attribute_value) {
							return -1;
						}
						if (
							a?.concept_attribute_value.length > 2 &&
							b?.concept_attribute_value.length > 2
						) {
							if (
								a?.concept_attribute_value?.toString() <
								b?.concept_attribute_value?.toString()
							) {
								return -1;
							}
						}
						return 0;
					} else {
						return -1;
					}
				});
			} catch (err) {
				console.log(err);
			}
		} else {
			try {
				arr.sort(function (a, b) {
					if (a[_columnName]?.toString() < b[_columnName]?.toString()) {
						return 1;
					}
					if (a[_columnName]?.toString() > b[_columnName]?.toString()) {
						return -1;
					}
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		}
		return arr;
	};

	const gotoPatientView = (listItem, dataType) => {
		const cohortId = criteria.cohort ? criteria.cohort.value : base_cohort;

		const _criteria = {
			row: { value: '2', label: 'Patients' },
			column: { value: '2', label: 'Individual annotation results' },
			dataType:
				dataType == 1
					? { value: '1', label: 'Unstructured' }
					: { value: '2', label: 'Structured' },
			cohort: { value: cohortId, label: '' },
		};

		if (listItem.concept_id) {
			_criteria['concept'] = {
				value: listItem.concept_id,
				label: '',
				Is_concept_value_result_type: listItem.Is_concept_value_result_type || 'false',
			};
			return JSON.stringify(_criteria);
		}
		if (listItem.event_id) {
			let events = props.redCohort?.events || [];

			let event = events.filter((item) => item.event_id == listItem.event_id);

			let eventName = (event.length > 0 && event[0].name) || '';

			_criteria['event'] = { value: listItem.event_id, label: eventName };
			return JSON.stringify(_criteria);
		}
	};

	// const gotoPatientList = (cohort_id, concept_id, event_id, isPatientWithConcept, items) => {
	// 	setHistory(false, props.study.studyDetail.study_name);
	// 	let { userRole } = props.redSidenav;
	// 	if (userRole.Role == roleAccess.ANNOTATOR) {
	// 		setQueryParams({
	// 			pathname: constants.ROUTE_URL + '/dashboard/datalist/',
	// 			query: {
	// 				cohort_id: cohort_id,
	// 				concept_id: concept_id || '',
	// 				event_id: event_id || 0,
	// 				flagForPatientWithConcept: isPatientWithConcept,
	// 				forResult: items.Is_concept_value_result_type || false,
	// 			},
	// 		});
	// 	} else {
	// 		setQueryParams({
	// 			pathname: constants.ROUTE_URL + '/dashboard/patientlist/',
	// 			query: {
	// 				cohort_id: cohort_id,
	// 				concept_id: concept_id || '',
	// 				event_id: event_id || 0,
	// 				flagForPatientWithConcept: isPatientWithConcept,
	// 				forResult: items.Is_concept_value_result_type || false,
	// 			},
	// 		});
	// 	}
	// };

	const gotoPatientListKappa = (concept_id) => {
		const cohort_id = (criteria.cohort && criteria.cohort.value) || '';
		const studyId = props.match.params.studyId;
		let path = '';
		path = `${constants.ROUTE_URL}/dashboard/patientlist/` + `?`;
		path += `study_id=${studyId}`;
		path += `&concept_id=${concept_id || ''}`;
		path += `&event_id=${0}`;
		path += `&flagForPatientWithConcept=${true}`;
		path += `&forKappaScore=${true}`;

		return path;
	};

	const gotoPatientDocument = (isPatient, detail_data, studyDetail) => {
		if (isPatient) {
			setHistoory({
				...window.location,
				display_name: `Study detail: ${studyDetail.study_name}`,
			});
			props.history.push(
				`${constants.ROUTE_URL}/dashboard/patient/${detail_data.patient_id}`
			);
		} else {
			setHistoory({
				...window.location,
				display_name: `Study detail: ${studyDetail.study_name}`,
			});
			setQueryParams({
				pathname: `${constants.ROUTE_URL}/dashboard/document/${detail_data.doc_id}`,
				query: {
					doc_name: detail_data.doc_name,
				},
			});
		}
	};

	// const gotoDocument = (doc_detail, doc_type) => {
	// 	setDocName(doc_detail.doc_name);
	// 	setQueryParams({
	// 		pathname: constants.ROUTE_URL + '/dashboard/document/' + doc_detail.doc_id,
	// 		query: {
	// 			doc_name: doc_detail.doc_name,
	// 			route_doc_status: doc_type,
	// 			route_doc_manual_annotation: doc_detail.manual_annotation_result,
	// 			route_annotation_id: doc_detail.annotation_id,
	// 			route_doc_concept_id: doc_detail.document_concept_id,
	// 			route_from: 'study',
	// 		},
	// 	});
	// };
	const setHistoryForStudy = () => {
		setHistory(false, props.study.studyDetail.study_name);
	};

	const getKappaStatus = () => {};
	let subTitle = (criteria.column && criteria.column.label) || 'Accuracy';

	useEffect(() => {
		if (criteria && criteria.row && criteria.row.value != 1) {
		} else {
			let conceptList =
				studyValidity &&
				studyValidity.map((studylist) => {
					let severityName = '';
					if (studylist.severity) {
						severityName = studylist.severity;
					}
					if (studyConcepts && studyConcepts[0]) {
						if (studylist.concept_id) {
							const concept = studyConcepts.filter((items) => {
								if (studylist.concept_id == items?.id) {
									return items;
								}
								if (studylist.concept_id == items.event_id) {
									return items;
								}
							});

							let events = props.redCohort?.events || [];
							let event = events.filter((event) => {
								if (studylist.concept_id == event.event_id) {
									return event;
								}
							});
							let displayName = '';
							if (concept.length) {
								displayName = concept.length ? concept[0].text : '';
							} else if (event.length) {
								displayName = event.length ? `${event[0].name} (event)` : '';
							}

							studylist.display_name =
								displayName +
								(severityName ? ` (${severityName})` : '') +
								(studylist.concept_attribute_name == 'NumericResult'
									? ` (result)`
									: '');
						}
					}
					if (events) {
						if (studylist.event_id) {
							const event =
								events &&
								events.filter((items) => {
									if (studylist.event_id == items.event_id) {
										return items;
									}
								});
							var displayName = event.length ? event[0].name : '';
							studylist.display_name =
								`${displayName +
								(severityName ? ' (' + severityName + ')' : '') 
								} (event)`;
							if (studylist.Is_concept_value_result_type) {
								studylist.display_name = `${studylist.display_name} (result)`;
							}
						}
					}
					return studylist;
				});
		}
	}, [studyConcepts, props?.redCohort?.events]);

	if (columnName.indexOf('KappaConceptView') == -1 && loading == false && studyValidity?.length) {
		studyValidity = sortOption(studyValidity);
	}

	const onToggle = (e, event, meta) => {
		if (meta.source === 'rootClose') {
			setDropDownShow(e);
		}
	};

	const onDropdownItem = (index) => {
		if (commonHeader[index].checked) {
			commonHeader[index].checked = false;
		} else {
			commonHeader[index].checked = true;
		}
		setCommonHeader([...commonHeader]);
		sortASC('display_name', 'asc');
		setObjectToLocal('filterSelection', commonHeader);
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					studyId={props.match.params.studyId}
					{...props}
				/>
			</ErrorBoundary>
			<div>
				<h3 className="v-cohort-name cohort-name-item">
					{subTitle}: {studyDetail.study_name}
					{conceptView && (
						<Dropdown
							show={dropDownShow}
							drop="start"
							align="start"
							alignRight
							onToggle={onToggle}
						>
							<Dropdown.Toggle
								variant="success"
								id="study-validity-dropdown"
								onClick={() => {
									setDropDownShow(!dropDownShow);
								}}
							></Dropdown.Toggle>

							<Dropdown.Menu
								show={dropDownShow}
								autoClose="false"
								className="sv-filter-dropdown"
							>
								{commonHeader.map(function (item, i) {
									return (
										<Fragment>
											{item.display ? (
												<Dropdown.Item className="study-validity-dropdown-item">
													<td key={i} onClick={() => onDropdownItem(i)}>
														<div className="study-validity-wrapper-checkbox">
															{item.checked ? (
																<i class="fas fa-check-square study-validity-checkbox"></i>
															) : (
																<i class="far fa-square study-validity-checkbox"></i>
															)}
															<span className="study-validity-text">
																{item.name}
															</span>
														</div>
													</td>
												</Dropdown.Item>
											) : (
												''
											)}
										</Fragment>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					)}
				</h3>
			</div>
			{(loading || isSearchConceptsByIdLoad) && (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			)}
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<StudyValidityDetails
						studyValidity={studyValidity}
						studyConcepts={studyConcepts}
						studyDetail={studyDetail}
						gotoPatientView={gotoPatientView}
						gotoPatientDocument={gotoPatientDocument}
						criteria={criteria}
						sortASC={sortASC}
						sortOrder={sortOrder}
						columnName={columnName}
						cohortKappa={cohortKappa}
						getKappaStatus={getKappaStatus}
						studyId={studyId}
						conceptKappaScore={conceptKappaScore}
						patientView={patientView}
						documentView={documentView}
						cohortView={cohortView}
						conceptKappaView={conceptKappaView}
						conceptView={conceptView}
						gotoConceptKappa={getConceptKappaScore}
						gotoPatientListKappa={gotoPatientListKappa}
						selectedCohortId={selectedCohortId}
						setHistoryForStudy={setHistoryForStudy}
						userRole={props.redSidenav}
						commonHeader={commonHeader}
						conceptKappaCallback={conceptKappaCallback}
						{...props}
					/>
				</Suspense>
			</ErrorBoundary>
			<Copyright />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		redSidenav: state.redSidenav,
		study: state.redStudy,
		loading: state.loading,
		redCohort: state.redCohort,
	};
};
export default connect(mapStateToProps)(StudyValidity);
StudyValidity.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			studyId: PropTypes.any,
		}),
	}),
	location: PropTypes.any,
	redCohort: PropTypes.any,
	study: PropTypes.object,
	loading: PropTypes.object,
};
