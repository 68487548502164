/* istanbul ignore file */
import { apiGet, apiPostForm } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';
import actions from '../../../actions';

export function getSubdocumentById(subddoc_id) {
	return new Promise(function (resolve, reject) {
		let storedData = store.getState();
		let storedSubdocument =
			storedData?.redDocument?.subdocumetDetails?.[`subdocument_${subddoc_id}`];
		if (storedSubdocument) {
			resolve(storedSubdocument);
		} else {
			apiGet('GetSubdocumentById', {
				m_documentId: subddoc_id,
			})
				.then((res) => {
					if (res?.data?.data) {
						store.dispatch({
							type: actionTypes.GET_SUBDOCUMENT_DETAIL,
							payload: { [`subdocument_${subddoc_id}`]: res },
						});
					}
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		}
	});
}

export function getConceptCategories() {
	return new Promise(function (resolve, reject) {
		apiGet('GetConceptCategories')
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_CONCEPT_CATEGORIES,
					payload: res.data.data || [],
				});
				resolve(res.data.data || []);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
export function getUserManualAnnotations(m_DocIdList) {
	return new Promise(function (resolve, reject) {
		apiGet('GetManualAnnotations', {
			'm_ManualAnnotationObject.subdocument_id': m_DocIdList,
			m_UserManualAnnotationFlag: true,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_MANUAL_ANNOTATIONS,
					payload: res.data.payload || [],
				});
				resolve(res.data.payload || []);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// }

export function deleteManualAnnotation(annotation_id) {
	return new Promise(function (resolve, reject) {
		apiGet('DeleteManualAnnotations', {
			'm_ManualAnnotationObject.annotation_id': annotation_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_AUTOMATED_ANNOTATION_LIST_FOR_DOCUMENT,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function AddManualAnnotationsInBulk(data, sectionalData) {
	let annotations =  getSectionDetails(data, sectionalData);
	return new Promise(function (resolve, reject) {
		apiPostForm('AddManualAnnotationsInBulk', annotations)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

function getSectionDetails (data, subDocumentFromStorage) {
	if (subDocumentFromStorage[0]) {
		data.map((val) => {
			if (val.section_number >= 0) {
				const section = subDocumentFromStorage[val.section_number];
				if (val.startPosition >= 0) {
					val.narrative_offset_startPosition = (
						parseInt(section.section_text_start_offset || 0) + parseInt(val.startPosition)
					).toString();
				}
				if (val.endPosition >= 0) {
					val.narrative_offset_endPosition = (
						parseInt(section.section_text_start_offset || 0) + parseInt(val.endPosition)
					).toString();
				}
			} else {
				val.narrative_offset_startPosition = '0';
				val.narrative_offset_endPosition = '0';
			}
		});
	}
	return data;
}
