import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import { Link } from 'react-router-dom';
import { setQueryParams, setObjectToLocal, getObjectFromLocal } from '../../../utilities/utils';
import queryString from 'query-string';
import axios from 'axios';
import MessagePopup from '../../../components/MessagePopup/MessagePopup';
import { setHistory, setFilterLabel } from '../../../common/FilterFunctions/FilterFunctions';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { setIsLoading } from '../../../actions/common';
import {
	getStudyDetail,
	searchConceptsById,
	getSubdocumentById,
	getManualAnnotationsWithConflicts,
	getUserDetails,
	resolveManualAnnotationConflicts,
} from './DataResolveStudyHelper';

import PaginationComponent from '../../../common/Pagination/PaginationComponent';
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
	// img: <img src={constants.IMG_URL+'data_icon.png'} className={'App-DataListImage'} alt={'Data List'} style={{width: '20px', marginRight: '5px'}}/>
};

const DataResolveStudy = (props) => {
	const [doc_name, setDoc_name] = useState('');
	const [doc_id, setDoc_id] = useState('');
	const [distinctDocument, setDistinctDocument] = useState([]);
	const [openDocIndex, setOpenDocIndex] = useState(0);
	const [openConceptIndex, setOpenConceptIndex] = useState(0);
	const [totalDocumentCount, setTotalDocumentCount] = useState(-1);
	const [pageNumber, setPageNumber] = useState(1);
	const [userList, setUserList] = useState([]);
	const [study_name, setStudy_name] = useState('');
	const [pageSize, setPageSize] = useState(10);
	const [conceptName, setConceptName] = useState('');
	const [content_type, setContent_type] = useState('');
	let { loading } = props.loading;
	let concept_id = queryString.parse(props?.location?.search).concept_id;
	const messagePopup = useRef();

	useEffect(() => {
		setObjectToLocal('selectedAnnotation', []);
		let { docId } = props.match.params;
		let study_id = queryString.parse(props?.location?.search).study_id;
		let concept_id = queryString.parse(props?.location?.search).concept_id;
		setIsLoading(true);
		getStudyDetail(study_id).then((studyDetail) => {
			setStudy_name(studyDetail.study_name);
			if (concept_id) {
				searchConceptsById(concept_id).then((resp) => {
					if (resp) {
						setConceptName(resp[0].text);
					}
					if (concept_id && study_id) {
						let filterLabel = {
							page: 'datastudyreview',
							options: [
								{
									filterLabel: 'Concept',
									name: conceptName,
									fixed: true,
								},
								{
									filterLabel: 'Study',
									name: studyDetail.study_name,
									fixed: true,
								},
							],
						};
						setFilterLabel(filterLabel);
					}
				});
			} else {
				let filterLabel = {
					page: 'datastudyreview',
					options: [
						{
							filterLabel: 'Study',
							name: studyDetail.study_name,
							fixed: true,
						},
					],
				};
				setFilterLabel(filterLabel);
			}
		});
		if (docId != 0) {
			getSubdocumentById(docId).then((resp) => {
				setDoc_name(resp.data.data.name_with_date);
				setContent_type(resp.data.data.content_type);
			});
		}
	}, []);

	useEffect(() => {
		if (props.redSidenav.userRole.Role) {
			getDocuments(1);
		}
	}, [props.redSidenav.userRole]);

	const getDocuments = (pageNumber, recordsPerPage = 10) => {
		let study_id = queryString.parse(props?.location?.search).study_id;
		let concept_id = queryString.parse(props?.location?.search).concept_id;
		let userIdList = queryString.parse(props?.location?.search).userIdList;
		let initial = queryString.parse(props?.location?.search).initial;
		let forKappaScore = queryString.parse(props?.location?.search).forKappaScore;

		//axios.get("/data/Review.json")
		setIsLoading(true);
		getManualAnnotationsWithConflicts(
			study_id,
			concept_id,
			userIdList,
			initial,
			pageNumber,
			recordsPerPage,
			forKappaScore
		)
			.then((res) => {
				setIsLoading(false);
				if (res.payload && res.payload.length > 0) {
					let documents = res.payload || [];
					let userIds = [];
					let totalCount = res.documentsCount;
					documents.map((doc, doc_index) => {
						doc.document_detail.map((concept, index) => {
							concept.annotation_details.map((annotation, annIndex) => {
								userIds.push(annotation.user_id);
							});
						});
					});
					userIds = [...new Set(userIds.map((items) => items))];
					getUserDetails(userIds.join(',')).then((res) => {
						setUserList(res.data.data);
						documents.map((doc, doc_index) => {
							doc.document_detail.map((concept, index) => {
								concept.annotation_details.map((annotation, annIndex) => {
									let user = res.data.data.filter(
										(item) => item.user_id == annotation.user_id
									)[0];
									let userName = `${user.first_name} ${user.last_name}`;
									documents[doc_index].document_detail[index].annotation_details[
										annIndex
									].user_name = userName;
								});
							});
							let distinctconcept = [
								...new Set(doc.document_detail.map((items) => items.concept_id)),
							];
							if (distinctconcept.length > 0) {
								distinctconcept.map((conceptId) => {
									let coceptAnnotation = doc.document_detail.filter(
										(items) => items.concept_id == conceptId
									);
									if (!documents[doc_index].concept_detail) {
										documents[doc_index].concept_detail = [];
									}

									documents[doc_index].concept_detail.push({
										conceptId: conceptId,
										conceptName: coceptAnnotation[0].concept_name,
										annotations: coceptAnnotation,
									});
								});
								delete doc.document_detail;
							}
						});
						if (pageNumber == 1) {
							setDistinctDocument(documents);
							setTotalDocumentCount(totalCount);
							setPageNumber(pageNumber);
						} else {
							setDistinctDocument(documents);
							setPageNumber(pageNumber);
						}
					});
				}
			})
			.then((err) => {
				setIsLoading(false);
			})
			.finally((res) => {
				setIsLoading(false);
			});
	};

	const redirectToDocument = (docId) => {
		setQueryParams({
			pathname: `${constants.ROUTE_URL}/dashboard/document/${docId}`,
		});
	};

	const redirectToDocumentWithAnnotation = (annotation) => {
		return (
			`${constants.ROUTE_URL 
				}/dashboard/document/${ 
				annotation.subdocument_id 
				}?route_annotation_id=${ 
				annotation.annotation_id}` || ''
		);
	};
	const gotoDatadetail = () => {
		props.history.push(`${constants.ROUTE_URL}/dashboard/document/${props.match.params.docId}`);
	};

	const openDocumentContent = (index) => {
		if (openDocIndex == index) {
			setOpenDocIndex(-1);
			setOpenConceptIndex(-1);
		} else {
			setOpenDocIndex(index);
			setOpenConceptIndex(-1);
		}
	};

	const openConceptContent = (index) => {
		if (openConceptIndex == index) {
			setOpenConceptIndex(-1);
		} else {
			setOpenConceptIndex(index);
		}
	};

	const gotoPage = (pageNumber, recordsPerPage) => {
		setOpenDocIndex(-1);
		setOpenConceptIndex(-1);

		getDocuments(pageNumber, recordsPerPage);
	};

	const checkRadio = (doc_index, concept_index, annotation, annIndex, annotationSequnce) => {
		let storedAnnotation = getObjectFromLocal('selectedAnnotation');
		let _distinctDocument = Object.assign([], distinctDocument);
		let storedConceptIndex = -1;
		let currentConceptId = _distinctDocument[doc_index].concept_detail[concept_index].conceptId;
		let currentDocumentId = _distinctDocument[doc_index].document_id;

		storedAnnotation.map((items, index) => {
			if (
				items.selectedConcept == currentConceptId &&
				items.selectedDoc == currentDocumentId &&
				items.annotationIndex == annIndex
			) {
				storedConceptIndex = index;
			}
		});

		let selected_annotation = 0;
		let annotationList = [];

		_distinctDocument[doc_index].concept_detail[concept_index].annotations[
			annIndex
		].annotation_details.map((ann, detailIndex) => {
			if (annotation.annotation_id == ann.annotation_id) {
				selected_annotation = ann.annotation_id.toString();
				_distinctDocument[doc_index].concept_detail[concept_index].annotations[
					annIndex
				].annotation_details[detailIndex].isChecked = true;
			}
			if (annotation.annotation_id != ann.annotation_id) {
				annotationList.push(ann.annotation_id);
				_distinctDocument[doc_index].concept_detail[concept_index].annotations[
					annIndex
				].annotation_details[detailIndex].isChecked = false;
			}
		});
		if (storedConceptIndex != -1) {
			storedAnnotation[storedConceptIndex].selected_annotation = selected_annotation;
			storedAnnotation[storedConceptIndex].annotationList = annotationList.join(',');
			storedAnnotation[storedConceptIndex].annotationIndex = annIndex;
		} else {
			storedAnnotation.push({
				selectedConcept: currentConceptId,
				selectedDoc: currentDocumentId,
				selected_annotation: selected_annotation,
				annotationList: annotationList.join(','),
				annotationIndex: annIndex,
			});
		}

		setObjectToLocal('selectedAnnotation', storedAnnotation);
		// _distinctDocument.map((doc,docIndex)=>{
		//     doc.concept_detail.map((concept,concIndex)=>{
		//         concept.annotations.map((ann,annIndex)=>{
		//             let storedConceptIndex = -1;

		//             if(doc_index==docIndex && concept_index == concIndex && annotation.annotation_id == ann.annotation_id){
		//                 _distinctDocument[doc_index].concept_detail[concept_index].annotations[annIndex].isChecked = true;
		//             }
		//             if(doc_index==docIndex && concept_index == concIndex && annotation.annotation_id != ann.annotation_id){
		//                 _distinctDocument[doc_index].concept_detail[concept_index].annotations[annIndex].isChecked = false;
		//             }
		//         })
		//     })
		// })
		setDistinctDocument(_distinctDocument);
	};
	const saveClicked = () => {
		let storedAnnotation = getObjectFromLocal('selectedAnnotation');
		setIsLoading(true);
		if (storedAnnotation.length > 0) {
			let resolveAnnotationsList = [];

			storedAnnotation.map((items) => {
				resolveAnnotationsList.push({
					selected_annotation: items.selected_annotation.toString(),
					annotationList: items.annotationList,
				});
			});

			resolveManualAnnotationConflicts(resolveAnnotationsList).then((annotation) => {
				let { docId } = props.match.params;
				let study_id = queryString.parse(props?.location?.search).study_id;
				let concept_id = queryString.parse(props?.location?.search).concept_id;
				if (concept_id) {
					props.history.push(
						`${constants.ROUTE_URL 
							}/dashboard/datastudyreview/${ 
							props.match.params.docId 
							}?study_id=${ 
							study_id 
							}&concept_id=${ 
							concept_id}`
					);
				} else {
					props.history.push(
						`${constants.ROUTE_URL 
							}/dashboard/datastudyreview/${ 
							props.match.params.docId 
							}?study_id=${ 
							study_id}`
					);
				}

				// setQueryParams({
				//     pathname: constants.ROUTE_URL + '/dashboard/datareview/' + docId

				// });
			});
		} else {
			setIsLoading(false);
			messagePopup.current?.handleClick();
		}
	};
	const cancelClicked = () => {
		//let { docId } = props.match.params;
		let study_id = queryString.parse(props?.location?.search).study_id;
		props.history.push(
			`${constants.ROUTE_URL 
				}/dashboard/datastudyreview/${ 
				props.match.params.docId 
				}?study_id=${ 
				study_id}`
		);
	};

	const sortByUsername = (annotations) => {
		return annotations.sort(function (a, b) {
			var nameA = a.user_name.toLowerCase(),
				nameB = b.user_name.toLowerCase();
			if (nameA < nameB)
				//sort string ascending
				return -1;
			// if (nameA > nameB)
			//     return 1
			return 0; //default return value (no sorting)
		});
	};

	return (
		<div className="DataReview">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>
			<div className="document-header-container">
				<span> Resolve manual annotation conflicts: </span>
				<span>{concept_id ? conceptName : study_name}</span>
			</div>
			{distinctDocument &&
				distinctDocument.map((doc, doc_index) => {
					return (
						<React.Fragment>
							<div
								className="v-fold section-header"
								style={{ margin: '20px 0 0px 0' }}
							>
								<span
									key={doc_index}
									onClick={() => openDocumentContent(doc_index)}
									//{`v-fold_trigger hyperlink ${isOpen ? "open" : ""}`}
									className={`  v-fold_trigger ${
										openDocIndex == doc_index ? 'open' : ''
									}`}
								>
									{' '}
								</span>
								<span
									className="hyperlink"
									onClick={() => redirectToDocument(doc.document_id)}
								>
									{' '}
									{doc.document_name}
								</span>
							</div>

							{openDocIndex == doc_index &&
								doc.concept_detail &&
								doc.concept_detail.map((concept, concept_index) => {
									let conceptName =
										concept.annotations[0].annotation_details[0].concept_name;
									return (
										<React.Fragment>
											<div className="v-fold alt-bg ">
												<div
													onClick={() =>
														openConceptContent(concept_index)
													}
													className={`section-text v-title-wrapper v-fold_trigger ${
														openConceptIndex == concept_index
															? 'open'
															: ''
													}`}
												>
													{conceptName}
												</div>
											</div>
											{openConceptIndex == concept_index &&
												concept.annotations.map((annotation, annIndex) => {
													annotation.annotation_details = sortByUsername(
														annotation.annotation_details
													);
													let annotationOne =
														annotation.annotation_details[0];
													let annotationTwo =
														annotation.annotation_details[1];
													return (
														<React.Fragment>
															<div className="alt-bg">
																<div
																	className="group-one-study "
																	style={{
																		marginLeft: 110,
																	}}
																>
																	{annotationOne.isChecked ? (
																		<span
																			style={{
																				marginRight: 10,
																			}}
																			onClick={() =>
																				checkRadio(
																					doc_index,
																					concept_index,
																					annotationOne,
																					annIndex,
																					0
																				)
																			}
																			className="icon_radio_checked"
																		></span>
																	) : (
																		<span
																			style={{
																				marginRight: 10,
																			}}
																			onClick={() =>
																				checkRadio(
																					doc_index,
																					concept_index,
																					annotationOne,
																					annIndex,
																					0
																				)
																			}
																			className="icon_radio_unchecked"
																		></span>
																	)}
																	<span>
																		{annotationOne.user_name}
																	</span>
																	{': '}
																	<Link
																		className="hyperlink"
																		to={redirectToDocumentWithAnnotation(
																			annotationOne
																		)}
																	>
																		{annotationOne.is_present && (
																			<span>
																				{annotationOne.is_experienced
																					? ''
																					: 'No '}
																			</span>
																		)}
																		<span>{conceptName}</span>
																		{annotationOne.annotation_type !=
																			null && (
																			<span>
																				{annotationOne.is_present
																					? ''
																					: ' not mentioned '}
																			</span>
																		)}
																		<span>
																			{annotationOne.annotation_type ==
																			null
																				? ' not mentioned '
																				: ''}
																		</span>
																	</Link>
																</div>
															</div>
															<div className="alt-bg">
																<div
																	className="group-two-study"
																	style={{
																		marginLeft: 110,
																	}}
																>
																	{annotationTwo.isChecked ? (
																		<span
																			style={{
																				marginRight: 10,
																			}}
																			onClick={() =>
																				checkRadio(
																					doc_index,
																					concept_index,
																					annotationTwo,
																					annIndex,
																					1
																				)
																			}
																			className="icon_radio_checked"
																		></span>
																	) : (
																		<span
																			style={{
																				marginRight: 10,
																			}}
																			onClick={() =>
																				checkRadio(
																					doc_index,
																					concept_index,
																					annotationTwo,
																					annIndex,
																					1
																				)
																			}
																			className="icon_radio_unchecked"
																		></span>
																	)}
																	<span>
																		{annotationTwo.user_name}
																	</span>
																	{': '}
																	<Link
																		className="hyperlink"
																		to={redirectToDocumentWithAnnotation(
																			annotationTwo
																		)}
																	>
																		{annotationTwo.is_present && (
																			<span>
																				{annotationTwo.is_experienced
																					? ''
																					: 'No '}
																			</span>
																		)}
																		<span>{conceptName}</span>
																		{annotationTwo.annotation_type !=
																			null && (
																			<span>
																				{annotationTwo.is_present
																					? ''
																					: ' not mentioned '}
																			</span>
																		)}
																		<span>
																			{annotationTwo.annotation_type ==
																			null
																				? ' not mentioned '
																				: ''}
																		</span>
																	</Link>
																</div>
															</div>
														</React.Fragment>

														// <div style={{paddingLeft:110}} className="section-text alt-bg  v-title-wrapper" >
														//     {
														//         annotation.isChecked ?
														//         <span style={{marginRight:10}} onClick={()=>checkRadio(doc_index,concept_index,annotation)} className="icon_radio_checked"></span>
														//         :
														//         <span style={{marginRight:10}} onClick={()=>checkRadio(doc_index,concept_index,annotation)} className="icon_radio_unchecked"></span>
														//     }
														//     {
														//         annotation.user_name
														//     }: {" "}
														//     <span class="hyperlink" onClick={()=>redirectToDocumentWithAnnotation(annotation)}>
														//        {annotation.is_present && <span>{annotation.is_experienced? "": "No "}</span> }
														//        {annotation.concept_name}
														//        <span>{annotation.is_present? "": " not mentioned "}</span>
														//     </span>
														// </div>
													);
												})}
										</React.Fragment>
									);
								})}
						</React.Fragment>
					);
				})}
			{totalDocumentCount > 0 && (
				<ErrorBoundary>
					<PaginationComponent
						totalItemsCount={totalDocumentCount}
						gotoPage={gotoPage}
						currentPage={pageNumber}
					/>
				</ErrorBoundary>
			)}

			<ErrorBoundary>
				<MessagePopup
					ref={messagePopup}
					rootClosexit={() => {}}
					open={(c) => (messagePopup.current = c)}
					text={'Please select at least one conflicting record.'}
					confirmClick={() => {}}
				/>
			</ErrorBoundary>
			{totalDocumentCount > 0 && (
				<div className="datarapid-buttoncontainer">
					<div className="col-sm-6 text-right trialButton addNewTrial">
						<button
							data-test="cancel-btn"
							type="button"
							className="trialArm"
							onClick={cancelClicked}
						>
							Cancel
						</button>
						<button
							data-test="save-btn"
							type="button"
							className="trialArm"
							onClick={saveClicked}
						>
							Save
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

DataResolveStudy.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	location: PropTypes.shape({
		search: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.number,
		}),
	}),
	redSidenav: PropTypes.shape({
		userRole: PropTypes.shape({
			Role: PropTypes.any,
		}),
	}),
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataResolveStudy);
